<template>
    <div class="bg-white relative pt-4 shadow-md rounded-lg px-2 grid">
        <div class="absolute top-4 right-6 z-10">
            <ChartMultiSelect
                v-if="multiselectOptions.enabled"
                v-model:value="selectedMultiselectItems"
                :items="multiselectOptions.items"
                item-text="text"
                item-value="value"
                @closing="onMultiselectClosing"
            ></ChartMultiSelect>
        </div>
        <EmptyChartState :options="chartOptions" class="w-full grid">
            <GenericChart v-if="chartOptions" :options="chartOptions"></GenericChart>
        </EmptyChartState>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, unref, ref } from 'vue';
import merge from 'lodash-es/merge';
import GenericChart from '@/components/charts/GenericChart.vue';
import { Chart, ChartMultiSelectOptions } from '@/types';
import useChartAPI from '@/components/charts/composables/useChartAPI';
import EmptyChartState from '@/components/charts/EmptyChartState.vue';
import { ActionType } from '@/plugins/store/actions';
import ChartMultiSelect from '@/components/ui/ChartMultiSelect.vue';

export default defineComponent({
    components: { GenericChart, EmptyChartState, ChartMultiSelect },
    props: {
        chart: {
            type: String as () => Chart,
            required: true,
        },
        optionsCallback: {
            type: Function,
            required: true,
        },
        options: {
            type: Object,
            default: {},
        },
        height: {
            type: Number,
            default: 300,
        },
        multiselectOptions: {
            type: Object as () => ChartMultiSelectOptions,
            default: { enabled: false, items: [] },
        },
        addParametersCallback: {
            type: Function,
            default: () => '',
        },
    },
    setup(props) {
        const selectedMultiselectItems = ref([...props.multiselectOptions.items.filter((currentOption) => currentOption.defaultSelected)]);
        const addParameters = ref(props.addParametersCallback(props.multiselectOptions, selectedMultiselectItems.value));
        const { options, series, title } = useChartAPI(props.chart, ActionType.GetChart, props.multiselectOptions.enabled ? addParameters : null);

        const chartOptions = computed(() => {
            if (options.value) {
                return options;
            }
            if (series.value.length === 0) {
                return null;
            }

            return props.optionsCallback(
                merge(
                    {
                        title: { text: unref(title) },
                        series: unref(series),
                        chart: { height: props.height },
                    },
                    props.options
                )
            );
        });

        const onMultiselectClosing = () => {
            addParameters.value = props.addParametersCallback(props.multiselectOptions, selectedMultiselectItems.value);
        };

        return { chartOptions, selectedMultiselectItems, onMultiselectClosing };
    },
});
</script>

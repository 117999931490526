<template>
    <div class="space-y-8">
        <h2 class="text-2xl font-semibold text-center">{{ $t('views.settings.directories.content.modals.manageDirectory.header') }}</h2>
        <div class="flex space-x-4">
            <CustomButton :disabled="disableButtons" @click="onSubmitClicked">
                {{ $t('views.settings.directories.content.modals.manageDirectory.switchButtonLabel') }}
            </CustomButton>
            <CustomButton :disabled="disableButtons" @click="onSkipClicked">
                {{ $t('views.settings.directories.content.modals.manageDirectory.editButtonLabel') }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { Form } from '@makeabledk/vue-ui/support/http';
import { MultiStepFormState } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';

export default defineComponent({
    components: { Modal, CustomButton },
    emits: ['submit', 'skip'],
    props: {
        state: {
            type: Object as () => Form<MultiStepFormState> & MultiStepFormState,
            required: true,
        },
        config: {
            type: Object,
            default: null,
        },
    },
    data() {
        return { disableButtons: false };
    },
    methods: {
        onSubmitClicked() {
            if (!this.disableButtons) {
                this.disableButtons = true;
                this.$emit('submit');
            }
        },
        onSkipClicked() {
            if (!this.disableButtons) {
                this.disableButtons = true;
                this.$emit('skip');
            }
        },
    },
});
</script>

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28db90ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip relative inline-block" }
const _hoisted_2 = {
  key: 0,
  class: "tooltip-content z-20 invisible absolute bg-gray-800 text-white rounded py-2 px-3 divide-white divide-y top-0 right-0"
}
const _hoisted_3 = { class: "font-semibold text-lg" }
const _hoisted_4 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["z-10", [{ dotted: _ctx.enableTooltip }]])
    }, _toDisplayString(_ctx.text), 3),
    (_ctx.enableTooltip)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tooltipHeader), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locations, (location) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: location.id
                }, _toDisplayString(location.name), 1))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
<template>
    <Modal close-esc-event="keydown" :close-button="false" modal-class="m-auto border-0" mask-class="grid" @closing="onModalClose">
        <template #toggle="scope">
            <slot v-bind="scope"></slot>
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body>
            <div class="bg-gray-100 py-6 px-8 rounded-xl space-y-4">
                <Icon class="mx-auto w-12 h-12" :src="notification.icon_url"></Icon>
                <p>{{ notification.message }}</p>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { Notification } from '@/types';
import CloseModalButton from '@/components/common/CloseModalButton.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { MutationType } from '@/plugins/store/mutations';

export default defineComponent({
    components: { Modal, CloseModalButton },
    props: {
        notification: {
            type: Object as () => Notification,
            default: null,
        },
    },
    methods: {
        async onModalClose() {
            store.commit(MutationType.SetPendingChangesToNotifications, true);
            await store.dispatch(ActionType.EditNotification, { form: { notification: this.$props.notification, update: [{ value: true, path: '/read', op: 'replace' }] } });
            await Promise.all([
                store.dispatch(ActionType.GetNotifications, { options: { ignoreCache: true } }),
                store.dispatch(ActionType.GetMeta, { options: { ignoreCache: true, skipLoadingIndicator: true } }),
            ]);
            store.commit(MutationType.SetPendingChangesToNotifications, false);
        },
    },
});
</script>

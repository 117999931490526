<template>
    <div v-if="element" :class="colSpan">
        <CustomInput
            v-if="element.type === 'textbox' && computedElement.type_settings.input_type === 'string'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :label="computedElement.label"
            :disabled="computedElement.disabled || preconditionDisabled"
            input-type="text"
            :rules="rules"
            :details="preconditionHint || details"
            :show-required-indication="computedElement.required"
            @input="$emit('input')"
        ></CustomInput>

        <CustomInput
            v-else-if="element.type === 'textbox' && (computedElement.type_settings.input_type === 'int' || computedElement.type_settings.input_type === 'double')"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :label="computedElement.label"
            :step="computedElement.type_settings.input_type === 'int' ? '1' : '0.1'"
            :disabled="computedElement.disabled || preconditionDisabled"
            input-type="number"
            :rules="rules"
            :details="preconditionHint || details"
            :show-required-indication="computedElement.required"
            @input="$emit('input')"
        ></CustomInput>

        <CustomTextArea
            v-else-if="element.type === 'textarea' && computedElement.type_settings.input_type === 'string'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :label="computedElement.label"
            :disabled="computedElement.disabled || preconditionDisabled"
            :rules="rules"
            :details="preconditionHint || details"
            :show-required-indication="computedElement.required"
            @input="$emit('input')"
        ></CustomTextArea>

        <DistributionChartModal
            v-else-if="element.type === 'chartspread' && (computedElement.type_settings.input_type === 'int' || computedElement.type_settings.input_type === 'double')"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :label="computedElement.label"
            :amount="preconditionValues[0]"
            :average="preconditionValues[1]"
            :min="0.05"
            :max="0.3"
            :default="0.1"
            :disabled="computedElement.disabled || preconditionDisabled"
            :details="preconditionHint || details"
            @input="$emit('input')"
        ></DistributionChartModal>

        <CustomSelect
            v-else-if="computedElement.type_settings.input_type === 'keyvaluepair' && computedElement.type === 'dropdown'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :items="computedElement.type_settings.values"
            item-text="value"
            :disabled="computedElement.disabled || preconditionDisabled"
            return-object
            :rules="rules"
            item-value="id"
            required
            :allow-empty="!computedElement.required"
            :label="computedElement.label"
            :details="preconditionHint || details"
            :show-required-indication="computedElement.required"
            @input="onSelectInput"
        ></CustomSelect>

        <CustomMultiSelect
            v-else-if="computedElement.type_settings.input_type === 'keyvaluepairlist' && computedElement.type === 'multiselect'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :items="computedElement.type_settings.values"
            item-text="value"
            :disabled="computedElement.disabled || preconditionDisabled"
            :rules="rules"
            item-value="id"
            required
            :label="computedElement.label"
            :show-required-indication="computedElement.required"
            :details="preconditionHint || details"
            @input="$emit('input')"
        ></CustomMultiSelect>

        <RadioButtonGroup
            v-else-if="computedElement.type_settings.input_type === 'keyvaluepair' && computedElement.type === 'radiobutton'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :items="computedElement.type_settings.values"
            item-text="value"
            :disabled="computedElement.disabled || preconditionDisabled"
            return-object
            :rules="rules"
            item-value="id"
            :label="computedElement.label"
            :radio-button-group="`${sectionId}_${computedElement.id}`"
            :details="preconditionHint || details"
            @input="$emit('input')"
        ></RadioButtonGroup>

        <FormCheckbox
            v-else-if="computedElement.type_settings.input_type === 'bool' && computedElement.type === 'checkbox'"
            v-model:value="computedElement.value"
            :disabled="computedElement.disabled || preconditionDisabled"
            :label="computedElement.label"
        ></FormCheckbox>

        <DatePicker
            v-else-if="element.type_settings.input_type === 'datetime' && computedElement.type === 'datepicker'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :required="computedElement.required"
            :min="element.type_settings.minimum_value"
            :max="element.type_settings.maximum_value"
            :disabled="computedElement.disabled || preconditionDisabled"
            :label="computedElement.label"
            :details="preconditionHint || details"
        >
        </DatePicker>

        <TimePicker
            v-else-if="element.type_settings.input_type === 'datetime' && computedElement.type === 'clockpicker'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :required="computedElement.required"
            :disabled="computedElement.disabled || preconditionDisabled"
            :label="computedElement.label"
            :details="preconditionHint || details"
        >
        </TimePicker>

        <DateTimePicker
            v-else-if="element.type_settings.input_type === 'datetime' && computedElement.type === 'datetimepicker'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :required="computedElement.required"
            :min="element.type_settings.minimum_value"
            :max="element.type_settings.maximum_value"
            :disabled="computedElement.disabled || preconditionDisabled"
            :label="computedElement.label"
            :details="preconditionHint || details"
        >
        </DateTimePicker>

        <DateTimeRecurrencePicker
            v-else-if="element.type_settings.input_type === 'datetime_with_recurrence' && computedElement.type === 'datepicker_with_recurrence'"
            ref="dynamic-element"
            v-model:value="computedElement.value"
            :required="computedElement.required"
            :min="element.type_settings.minimum_value"
            :max="element.type_settings.maximum_value"
            :disabled="computedElement.disabled || preconditionDisabled"
            :label="computedElement.label"
            :details="preconditionHint || details"
        >
        </DateTimeRecurrencePicker>

        <div v-else-if="element.type === 'button' && element.type_settings.action === 'image-upload'" class="mb-2">
            <DynamicFormImages v-if="computedElement && element.type_settings.values && element.type_settings.values.length" v-model:element="computedElement" :assignment-id="assignmentId" />
        </div>

        <DynamicFormButton
            v-else-if="element.type === 'button'"
            ref="dynamic-element"
            :element="element"
            :disabled="computedElement.disabled"
            @clone-section="(cloneFormSectionOptions) => $emit('clone-section', cloneFormSectionOptions)"
            @backend-retrieval="(buttonElement) => $emit('backend-retrieval', buttonElement)"
        ></DynamicFormButton>

        <DynamicFormLink v-else-if="element.type === 'linkbutton'" :element="element" :disabled="computedElement.disabled"></DynamicFormLink>

        <DynamicFormQualityDescriptor v-else-if="element.type === 'qualitydescriptor'" :element="element" />

        <div v-else>{{ 'Label:' + element.label }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FormElement } from '@/types';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomTextArea from '@/components/ui/CustomTextArea.vue';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import CustomMultiSelect from '@/components/ui/CustomMultiSelect.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import TimePicker from '@/components/ui/TimePicker.vue';
import DateTimePicker from '@/components/ui/DateTimePicker.vue';
import DateTimeRecurrencePicker from '@/components/ui/DateTimeRecurrencePicker.vue';
import RadioButtonGroup from '@/components/ui/RadioButtonGroup.vue';
import FormCheckbox from '@/components/ui/FormCheckbox.vue';
import DistributionChartModal from '@/components/common/DistributionChartModal.vue';
import DynamicFormButton from '@/components/common/DynamicFormButton.vue';
import DynamicFormLink from '@/components/common/DynamicFormLink.vue';
import DynamicFormImages from '@/components/common/DynamicFormImages.vue';
import DynamicFormQualityDescriptor from '../DynamicFormQualityDescriptor.vue';

export default defineComponent({
    components: {
        CustomInput,
        CustomTextArea,
        CustomSelect,
        CustomMultiSelect,
        DatePicker,
        TimePicker,
        DateTimePicker,
        DateTimeRecurrencePicker,
        RadioButtonGroup,
        FormCheckbox,
        DistributionChartModal,
        DynamicFormButton,
        DynamicFormLink,
        DynamicFormImages,
        DynamicFormQualityDescriptor,
    },
    emits: ['update:element', 'input', 'clone-section', 'backend-retrieval'],
    props: {
        element: {
            type: Object as () => null | FormElement,
            required: true,
        },
        preconditionDisabled: {
            type: Boolean,
            default: false,
        },
        preconditionData: {
            type: Array as () => { label: string | undefined; value: any; id: string | undefined }[],
            default: [],
        },
        rules: {
            type: Array as () => CallableFunction[],
            default: [],
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
        sectionId: {
            type: String,
            default: '',
        },
        assignmentId: {
            type: Number,
            default: null,
        },
    },
    computed: {
        preconditionValues(): any[] {
            return this.$props.preconditionData.map((currentPreconditionData) => currentPreconditionData.value || null);
        },
        preconditionHint(): { type: 'hint' | 'warning'; text: string } | null {
            if (this.$props.preconditionDisabled) {
                return {
                    type: 'hint',
                    text: this.$t('global.forms.steps.dynamicForm.hints.preconditions').replace(
                        '$FIELDS',
                        `"${this.$props.preconditionData.map((currenPreconditionData) => currenPreconditionData.label).join(`", "`)}"`
                    ),
                };
            }
            return null;
        },
        /* If the input type we resolve to based on our v-if has a property called 'validInput' we return it.
            Otherwise, we assume that it is valid */
        validInput(): boolean {
            const element = this.$refs['dynamic-element'] as any;
            return Boolean(!element) || element.validInput === undefined || element.validInput;
        },
        computedElement: {
            get(): null | FormElement {
                return this.$props.element;
            },
            set(newElement: null | FormElement) {
                this.$emit('update:element', newElement);
            },
        },
        colSpan(): string {
            const colClasses = {
                1: 'col-span-1',
                2: 'col-span-2',
            };
            if (this.computedElement?.type === 'button' && this.computedElement?.type_settings.action === 'image-upload') {
                return colClasses[2];
            }
            return colClasses[(this.computedElement?.column_width as 1 | 2) || 2] || 'col-span-2';
        },
    },
    methods: {
        onSelectInput() {
            if (this.element?.type_settings.action === 'backend-retrival-trigger') {
                this.$emit('backend-retrieval', this.$props.element);
            }
            this.$emit('input');
        },
    },
});
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_SideBar = _resolveComponent("SideBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TopBar),
    _createVNode(_component_SideBar, null, {
      footer: _withCtx(() => [
        _createVNode(_component_Footer)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ]))
}
<template>
    <Modal close-esc-event="keydown" :close-button="false" size="full" :modal-class="`m-auto border-0 transition-width duration-500 ${modalClass}`" mask-class="grid" @closing="$emit('closing')">
        <template #toggle="scope">
            <slot v-bind="scope" />
        </template>

        <template #header="scope">
            <CloseModalButton @close="scope.close"></CloseModalButton>
        </template>

        <template #body="scope">
            <form class="bg-white relative rounded-lg grid py-6 px-10 m-auto transition-all transition-duration-2000 space-y-2 w-full outline-none" tabindex="0">
                <MultiStepFormHeaderIcon v-if="headerIcon" :icon-source="headerIcon" :is-final-step="isFinalStep"></MultiStepFormHeaderIcon>
                <transition name="fade" mode="out-in">
                    <slot name="component" v-bind="scope" />
                </transition>
            </form>
        </template>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import MultiStepFormHeaderIcon from '@/components/common/MultiStepFormHeaderIcon.vue';
import CloseModalButton from '@/components/common/CloseModalButton.vue';

export default defineComponent({
    components: { Modal, MultiStepFormHeaderIcon, CloseModalButton },
    props: {
        headerIcon: {
            type: [String, Number],
            required: true,
        },
        modalClass: {
            type: String,
            default: '',
        },
        isFinalStep: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['closing'],
    setup(props) {
        return props;
    },
});
</script>

<template>
    <div class="space-y-8 flex flex-col h-full">
        <h2 class="text-2xl font-semibold text-center">{{ $t('views.settings.directories.content.modals.switchConfirmationModal.header') }}</h2>
        <div class="text-center">{{ $t('views.settings.directories.content.modals.switchConfirmationModal.confirmationText').replace('$DIRECTORY', config.directory.name) }}</div>
        <div class="flex space-x-4 flex-end">
            <CustomButton color-preset="secondary" @click="$emit('close')">
                {{ $t('views.settings.directories.content.modals.switchConfirmationModal.cancelButtonLabel') }}
            </CustomButton>
            <CustomButton :disabled="disableButtons" @click="onConfirmButtonClicked">
                {{ $t('views.settings.directories.content.modals.switchConfirmationModal.confirmationButtonLabel') }}
            </CustomButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Modal } from '@makeabledk/vue-ui/components/modal';
import { Form } from '@makeabledk/vue-ui/support/http';
import { Directory, MultiStepFormState } from '@/types';
import CustomButton from '@/components/ui/CustomButton.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { Modal, CustomButton },
    emits: ['close'],
    props: {
        state: {
            type: Object as () => Form<MultiStepFormState> & MultiStepFormState,
            required: true,
        },
        config: {
            type: Object as () => { directory: Directory },
            default: null,
        },
    },
    data() {
        return { disableButtons: false };
    },
    methods: {
        async onConfirmButtonClicked() {
            this.disableButtons = true;
            await store.dispatch(ActionType.ActivateDirectory, { form: { directoryId: this.$props.config.directory.id } });
            window.location.reload();
        },
    },
});
</script>

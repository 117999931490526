import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_2 = { class: "flex mx-6 space-x-6 pt-4 min-h-14" }
const _hoisted_3 = { class: "text-lg font-medium my-auto flex-1" }
const _hoisted_4 = {
  key: 0,
  class: "text-right mr-6 w-80"
}
const _hoisted_5 = { class: "overflow-x-auto" }
const _hoisted_6 = { class: "border-t border-black flex py-4 px-6 gap-x-4 odd:bg-dark-gray-200" }
const _hoisted_7 = {
  key: 1,
  class: "text-center w-full font-semibold py-4 px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.tableData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.tableData.title), 1),
            (_ctx.tableData.searchbar)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createVNode(_component_SearchInput, {
                    value: _ctx.search,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    label: _ctx.tableData.searchbar.label
                  }, null, 8, ["value", "label"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_CustomTable, {
              headers: _ctx.headers,
              items: _ctx.filteredItems
            }, _createSlots({ _: 2 }, [
              (_ctx.tableData.footers && _ctx.tableData.footers.length)
                ? {
                    name: "append-rows",
                    fn: _withCtx(({ getWidth, getHeaderClass }) => [
                      _createElementVNode("tr", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData.headers, (header) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: header.value,
                            class: _normalizeClass(`${getHeaderClass(header, false)} my-auto break-words min-h-6.5 font-medium`),
                            style: _normalizeStyle(`max-width: ${getWidth(header)}px; width: 100%;`)
                          }, _toDisplayString(_ctx.styleFooterCell(header, _ctx.tableData.footers)), 7))
                        }), 128))
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["headers", "items"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('global.tables.emptyTableMessage')), 1))
  ]))
}
<template>
    <div class="space-y-6">
        <div v-for="item in populatedItems" :key="item.farm_id" class="border border-gray-300 rounded-lg px-4 py-5">
            <div class="flex items-center space-x-3">
                <div>
                    <Icon class="h-6" :src="IconSource.FarmsPrimary"></Icon>
                </div>
                <div>
                    <div class="font-bold text-lg">{{ item.farm_name }}</div>
                    <div class="text-sm text-gray-500">
                        {{ item.barns.length }} {{ item.barns.length === 1 ? $t('views.monitoring.main.headings.barn') : $t('views.monitoring.main.headings.barnPlural') }}, {{ item.sectionCount || 0 }}
                        {{ item.sectionCount === 1 ? $t('views.monitoring.main.headings.section') : $t('views.monitoring.main.headings.sectionPlural') }}
                    </div>
                </div>
            </div>
            <div class="mt-5 space-y-5">
                <OverviewTable v-for="barn in item.barns" :key="barn.headers.barn_id" :barn="barn" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { IconSource } from '@/types';
import Icon from '@/components/icons/Icon.vue';
import OverviewTable from './OverviewTable.vue';
import { MonitoringDataItem } from '../MonitoringTabs.vue';

export default defineComponent({
    components: { Icon, OverviewTable },
    props: {
        items: {
            type: Array as () => MonitoringDataItem[],
            required: true,
        },
    },
    setup(props) {
        const populatedItems = computed<any[]>(() =>
            props.items.map((i) => ({
                ...i,
                barns: i.barns.map((b) => ({
                    ...b,
                    rows:
                        b.rows?.map((r) => ({
                            ...r,
                            _treatments: r.due_treatments?.treatments || [],
                            _waterConsumption: r.water_consumption.max_severity_level,
                            _checkIn: r.check_in.value,
                        })) || [],
                })),
            }))
        );

        return {
            populatedItems,
            IconSource,
        };
    },
});
</script>

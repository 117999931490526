<template>
    <div class="fixed w-full" style="z-index: 1030">
        <nav class="bg-primary-800 h-24">
            <div class="mx-auto max-w-dashboard">
                <div style="height: 100px" class="flex items-center h-24">
                    <div class="flex items-center h-full">
                        <div class="flex-shrink-0 h-full">
                            <LocationPicker></LocationPicker>
                        </div>
                    </div>
                    <div class="h-full w-full">
                        <div class="flex justify-end items-center divide-x divide-primary-700 h-full">
                            <div class="px-4 xl:px-6 h-full grid content-center">
                                <Settings></Settings>
                            </div>
                            <div class="px-4 xl:px-6 h-full grid content-center">
                                <LanguagePicker></LanguagePicker>
                            </div>
                            <div class="px-4 xl:px-6 h-full grid content-center">
                                <Notifications></Notifications>
                            </div>
                            <div v-if="hasRealTimeModuleEnabled" class="px-4 xl:px-6 h-full grid content-center">
                                <Notes></Notes>
                            </div>
                            <div v-if="hasAssignmentsMobuleEnabled" class="px-4 xl:px-6 h-full grid content-center">
                                <Assignments></Assignments>
                            </div>
                            <div class="pl-4 xl:pl-6 pr-6 h-full flex space-x-4 items-center content-center">
                                <div>
                                    <UpdateKeyFigures></UpdateKeyFigures>
                                </div>
                                <div>
                                    <CreateBatchAction class="my-auto"></CreateBatchAction>
                                </div>
                                <div>
                                    <CreateEventAction class="my-auto"></CreateEventAction>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import CreateEventAction from '@/components/layouts/dashboardLayout/topBar/events/CreateEventAction.vue';
import CreateBatchAction from '@/components/layouts/dashboardLayout/topBar/events/CreateBatchAction.vue';
import Notifications from '@/components/layouts/dashboardLayout/topBar/notifications/Notifications.vue';
import Notes from '@/components/layouts/dashboardLayout/topBar/notes/Notes.vue';
import Assignments from '@/components/layouts/dashboardLayout/topBar/assignments/Assignments.vue';
import Settings from '@/components/layouts/dashboardLayout/topBar/settings/Settings.vue';
import LanguagePicker from '@/components/layouts/dashboardLayout/topBar/language/LanguagePicker.vue';
import UpdateKeyFigures from '@/components/layouts/dashboardLayout/topBar/updateKeyFigures/UpdateKeyFigures.vue';
import LocationPicker from '@/components/layouts/dashboardLayout/topBar/locationPicker/LocationPicker.vue';
import { store } from '@/plugins/store';
import { Module } from '@/types';

export default defineComponent({
    components: { CreateEventAction, CreateBatchAction, Notifications, Notes, Assignments, Settings, LanguagePicker, UpdateKeyFigures, LocationPicker },
    setup() {
        const hasAssignmentsMobuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.Assignments));
        const hasRealTimeModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.RealTime));

        return {
            hasRealTimeModuleEnabled,
            hasAssignmentsMobuleEnabled,
        };
    },
});
</script>

<template>
    <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/* We might get a view for sending notifications or administering notifications settings in the future. */
export default defineComponent({});
</script>

import * as Cookies from 'js-cookie';
import ApiClient from '@/plugins/store/actions/api/ApiClient';
import { store } from '@/plugins/store';
import { MutationType } from '@/plugins/store/mutations';

enum Cookie {
    Refresh = 'iq_refresh',
    Token = 'iq_token',
    Client = 'client_id',
    Expiry = 'iq_token_expiry',
}

class Authentication {
    private static instance: Authentication | null = null;

    private static COOKIES_EXPIRATION_IN_DAYS = 200;

    private static REFRESH_TOKEN_SECONDS_BEFORE_EXPIRATION = 60; // Must be > 10

    private static CLIENT_ID_EXPIRES_ON_DATE = new Date(2038, 0, 19); // Latest possible date for 32-bit browsers

    constructor() {
        if (!Authentication.instance) {
            Authentication.instance = this;
        }
        return Authentication.instance;
    }

    initAuth() {
        this.initClientId()
            .initialAuth()
            .then((authentication) => Authentication.waitUntilExpiry().then(() => authentication.refreshToken(true)));
    }

    initClientId() {
        let clientId = Cookies.get(Cookie.Client);
        if (!clientId) {
            clientId = `${Math.ceil(Math.random() * 10000) * Date.now()}`;
            Cookies.set(Cookie.Client, clientId, { expires: Authentication.CLIENT_ID_EXPIRES_ON_DATE });
        }

        return this;
    }

    /**
     *   Returns a promise that won't resolve until there's X amount of seconds until our token expires
     *   Resolves instantly if the token expired in the past
     */
    static waitUntilExpiry() {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                const expiry = Cookies.get(Cookie.Expiry);
                if (!expiry) {
                    clearInterval(interval);
                    return resolve(true);
                }
                const tokenExpires = new Date(expiry);
                const millisecondsUntilExpiry = tokenExpires.valueOf() - Date.now().valueOf();
                if (millisecondsUntilExpiry < Authentication.REFRESH_TOKEN_SECONDS_BEFORE_EXPIRATION * 1000) {
                    clearInterval(interval);
                    return resolve(true);
                }
            }, (Authentication.REFRESH_TOKEN_SECONDS_BEFORE_EXPIRATION - 10) * 1000);
        });
    }

    /**
     *   Refreshes our JWT token instantly using the authState. If poll = true, we automatically keep refreshing a minute before each token expiration
     *   Pre-conditions: Requires this.authState.token, this.authState.clientId and this.authState.refreshToken to be set
     */
    async refreshToken(poll: boolean) {
        try {
            const refreshResponse: { expiry: string; token: string; refresh_token: string } = await ApiClient.send(
                'post',
                'auth/token/refresh',
                { skipAuthCheck: true, skipLanguageCheck: true },
                {
                    token: Cookies.get(Cookie.Token),
                    client_id: Cookies.get(Cookie.Client),
                    refresh_token: Cookies.get(Cookie.Refresh),
                }
            );
            this.setAuthState(refreshResponse.token);
            Cookies.set(Cookie.Token, refreshResponse.token, { expires: Authentication.COOKIES_EXPIRATION_IN_DAYS });
            Cookies.set(Cookie.Refresh, refreshResponse.refresh_token, { expires: Authentication.COOKIES_EXPIRATION_IN_DAYS });
            Cookies.set(Cookie.Expiry, refreshResponse.expiry, { expires: Authentication.COOKIES_EXPIRATION_IN_DAYS });
            if (poll) {
                await Authentication.waitUntilExpiry();
                this.refreshToken(true);
            }
        } catch (error) {
            this.redirectToLoginPage();
        }

        return this;
    }

    /**
     *  Tries authenticating via query param provided by DC
     *  If query param doesn't exist, tries with refresh/jwt token saved as cookie
     *  If refresh/jwt doesn't exist, redirects to login page
     *  Pre-conditions: Requires this.authState.clientId to be set
     *  Post-conditions: Requires this.authState.refresh_token, this.authState.token and this.authState.expiry to be set
     */
    async initialAuth() {
        const queryParams = window.location.search;
        const hash = window.location.hash;

        const dcTokenMatch = queryParams.match(/\?token=(.*)$/);
        if (hash || dcTokenMatch?.length === 2) {
            const token = hash ? hash.substring(10) : (dcTokenMatch as string[])[1];

            const iqTokenResponse: { expiry: string; refresh_token: string; token: string } = await ApiClient.send(
                'post',
                'auth/token',
                { skipAuthCheck: true, skipLanguageCheck: true },
                {
                    token,
                    client_id: Cookies.get(Cookie.Client),
                }
            );

            Cookies.set(Cookie.Refresh, iqTokenResponse.refresh_token, { expires: Authentication.COOKIES_EXPIRATION_IN_DAYS });
            Cookies.set(Cookie.Token, iqTokenResponse.token, { expires: Authentication.COOKIES_EXPIRATION_IN_DAYS });
            Cookies.set(Cookie.Expiry, iqTokenResponse.expiry, { expires: Authentication.COOKIES_EXPIRATION_IN_DAYS });
            window.history.replaceState({}, document.title, window.location.href.replace(/(\?token=.*$)|(#id_token=.*$)/, ''));
            this.setAuthState(iqTokenResponse.token);
        } else if (!Cookies.get(Cookie.Refresh) || !Cookies.get(Cookie.Token)) {
            this.redirectToLoginPage();
        } else if (Cookies.get(Cookie.Expiry) && new Date(Cookies.get(Cookie.Expiry)!).getTime() <= new Date().getTime()) {
            await this.refreshToken(false);
        } else {
            this.setAuthState(Cookies.get(Cookie.Token) || '');
        }

        return this;
    }

    setAuthState(token: string) {
        ApiClient.setAuthToken(token);
        const encodedUserPermissions = token.match(/.*\.(.*)\./);
        if (encodedUserPermissions && encodedUserPermissions.length === 2) {
            const urlSafeEncodedUserPermissions = encodedUserPermissions[1].replaceAll(/-/g, '+').replaceAll(/_/g, '/');
            const activeDirectory = JSON.parse(atob(urlSafeEncodedUserPermissions))?.ad || null;
            store.commit(MutationType.SetActiveDirectory, activeDirectory);
            store.commit(MutationType.SetUserRole, JSON.parse(atob(urlSafeEncodedUserPermissions))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
        }
        return this;
    }

    /* eslint-disable class-methods-use-this */
    redirectToLoginPage() {
        window.location.href = `${process.env.VUE_APP_LOGIN_URL}${window.location.href}?token=`;
    }

    getToken() {
        return Cookies.get(Cookie.Token);
    }

    logout(redirect: boolean) {
        Cookies.remove(Cookie.Refresh);
        Cookies.remove(Cookie.Token);
        Cookies.remove(Cookie.Expiry);

        if (redirect) {
            window.location.href = process.env.VUE_APP_LOGOUT_REDIRECT_URL || '';
        }
    }
}

const instance = new Authentication();
Object.freeze(instance);

export default instance;

<template>
    <table class="w-full">
        <thead>
            <tr class="px-6 flex my-2 gap-x-3 rounded text-[0.9rem] text-gray-500">
                <slot
                    v-for="header of headers"
                    :key="header.value"
                    :name="[`header_${header.value}`]"
                    :header="header"
                    :setItemRef="setItemRef"
                    :getHeaderClass="getHeaderClass"
                    :onHeaderClicked="onHeaderClicked"
                    :getHeaderSortIcon="getHeaderSortIcon"
                >
                    <th :key="header.value" :ref="(el) => setItemRef(el, header)" class="flex flex-1" :class="`${getHeaderClass(header, header.sortable)}`" @click="onHeaderClicked(header)">
                        <div class="flex font-medium">
                            <span v-html="sanitizeHtml(header.text)"></span>
                            <span v-if="header.sortable" class="sort-icon flex ml-1.5" :class="{ visible: getHeaderSortIcon(header).active }">
                                <Icon class="my-auto min-w-2 w-2 h-3.5" :src="getHeaderSortIcon(header).icon"></Icon>
                            </span>
                        </div>
                    </th>
                </slot>
            </tr>
        </thead>
        <tbody v-if="sortedItems?.length">
            <template v-for="(item, index) of sortedItems" :key="index">
                <tr
                    :class="{ 'cursor-pointer': clickableItems, 'odd:bg-dark-gray-200 rounded': !expandItemsKey, 'border-t border-dark-gray-300': expandItemsKey }"
                    class="flex py-4 px-6 gap-x-3 text-[0.9rem]"
                    @click="clickableItems && $emit('item-clicked', item)"
                >
                    <slot
                        v-for="header of headers"
                        :key="`${index}_${header.value}`"
                        :name="[`${header.value}`]"
                        :item="item"
                        :getWidth="getWidth"
                        :getHeaderClass="getHeaderClass"
                        :header="header"
                        :index="index"
                        :getItemProperty="getItemProperty"
                        :expanded="isExpanded(index)"
                    >
                        <td
                            :key="`${index}_${header.value}`"
                            :class="`${getHeaderClass(header, clickableItems)} ${header.cellClass ? header.cellClass : ''} my-auto break-words min-h-6.5`"
                            :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                        >
                            {{ getItemProperty(header, item, { numberFormat: header.numberFormat }) }}
                        </td>
                    </slot>
                </tr>
                <ExpandCollapseTransition>
                    <div v-if="isExpanded(index) && item[expandItemsKey]?.length" class="expand-container bg-dark-gray-200" :class="{ 'expand-container--last': index === sortedItems.length - 1 }">
                        <tr
                            v-for="(expandItem, expandItemIndex) of item[expandItemsKey]"
                            :key="`expand_${index}_${expandItemIndex}`"
                            class="flex py-4 px-6 gap-x-3 bg-dark-gray-200 text-[0.9rem]"
                            :class="{ 'border-t border-dark-gray-400': expandItemIndex !== 0 }"
                        >
                            <slot
                                v-for="header of headers"
                                :key="`${index}_${header.value}`"
                                :name="[`${header.value}-expand`]"
                                :item="expandItem"
                                :getWidth="getWidth"
                                :getHeaderClass="getHeaderClass"
                                :header="header"
                                :index="index"
                                :getItemProperty="getItemProperty"
                            >
                                <td
                                    :key="`${index}_${expandItemIndex}_${header.value}`"
                                    :class="`${getHeaderClass(header, clickableItems)} my-auto break-words min-h-6.5`"
                                    :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                                >
                                    {{ getItemProperty(header, expandItem, { numberFormat: header.numberFormat }) }}
                                </td>
                            </slot>
                        </tr>
                    </div>
                </ExpandCollapseTransition>
                <ExpandCollapseTransition>
                    <div v-if="isExpanded(index) && !expandItemsKey">
                        <tr class="flex py-4 px-6 border-t border-b border-t-gray-200 border-b-gray-200">
                            <slot name="expanded-row" :item="item"></slot>
                        </tr>
                    </div>
                </ExpandCollapseTransition>
            </template>
            <slot name="append-rows" :getWidth="getWidth" :getItemProperty="getItemProperty" :getHeaderClass="getHeaderClass"></slot>
        </tbody>
        <tbody v-else>
            <tr class="rounded flex py-4 px-6 gap-x-3 odd:bg-dark-gray-200">
                <td class="text-center w-full font-medium">
                    {{ $t('views.activityTable.table.emptyTableMessage') }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { TableHeader } from '@/types';
import useGetItemProperty from '@/components/common/composables/useGetItemProperty';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import useResizableTable from '@/composables/useResizableTable';
import useSortableTable from '@/composables/useSortableTable';

export default defineComponent({
    components: { ExpandCollapseTransition },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        headers: {
            type: Array as () => TableHeader[],
            default: () => [],
        },
        clickableItems: {
            type: Boolean,
            default: false,
        },
        expandedItems: {
            type: Array,
            default: () => [],
        },
        expandItemsKey: {
            type: String,
            default: '',
        },
        resizeDelay: {
            type: Number,
            default: 0,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['item-clicked', 'sort'],
    setup(props, { emit }) {
        const { getWidth, setItemRef } = useResizableTable({ resizeDelay: props.resizeDelay });
        const items = computed(() => props.items);
        const { sortedItems, getHeaderSortIcon, onHeaderClicked: headerClicked } = useSortableTable(items, props.headers);

        function getHeaderClass(header: TableHeader, clickable: boolean) {
            let result = '';
            switch (header.align) {
                case 'left': {
                    result = 'text-left';
                    break;
                }
                case 'right': {
                    result = 'text-right justify-end';
                    break;
                }
                default: {
                    result = 'text-center justify-center';
                }
            }

            result += clickable ? ' cursor-pointer' : ' cursor-auto';
            result += header.headerClass ? ` ${header.headerClass}` : '';

            return result;
        }

        function isExpanded(rowIndex: number) {
            return props.expandedItems.includes(rowIndex);
        }

        function onHeaderClicked(header: TableHeader) {
            headerClicked(header);

            if (header.sortable) {
                emit('sort');
            }
        }

        return {
            getHeaderClass,
            sanitizeHtml,
            getItemProperty: useGetItemProperty,
            isExpanded,
            getWidth,
            setItemRef,
            sortedItems,
            getHeaderSortIcon,
            onHeaderClicked,
        };
    },
});
</script>

<style scoped>
th .sort-icon {
    visibility: hidden;
}
th:hover .sort-icon {
    visibility: visible;
}

.expand-container {
    position: relative;
}

.expand-container:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 8px 8px -8px rgba(0, 0, 0, 0.25), inset 0px -8px 8px -8px rgba(0, 0, 0, 0.2);
    pointer-events: none;
}

.expand-container--last:after {
    box-shadow: inset 0px 8px 8px -8px rgba(0, 0, 0, 0.25);
}
</style>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "space-y-6 h-full min-h-60" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = {
  key: 0,
  class: "grid gap-y-4 h-full"
}
const _hoisted_4 = {
  key: 0,
  class: "text-lg font-semibold mb-2 text-center col-span-2"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "text-center text-primary-800 text-xl font-semibold" }
const _hoisted_7 = {
  key: 1,
  class: "grid grid-cols-10 gap-4"
}
const _hoisted_8 = { class: "col-span-4" }
const _hoisted_9 = {
  key: 0,
  class: "col-span-6"
}
const _hoisted_10 = {
  key: 2,
  class: "grid grid-cols-10 gap-x-4"
}
const _hoisted_11 = { class: "col-span-4" }
const _hoisted_12 = { class: "col-span-6" }
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationElement = _resolveComponent("ConfirmationElement")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ForceSubmissionModal = _resolveComponent("ForceSubmissionModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('global.forms.steps.confirmation.header')), 1),
    _createElementVNode("div", null, [
      (_ctx.computedState.form)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, sectionIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: section.id,
                class: _normalizeClass(["grid grid-cols-2 py-4 px-6", { 'bg-gray-100 rounded-lg': sectionIndex % 2 === 0 }])
              }, [
                (sectionIndex > 0)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(section.name), 1))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.children, (element) => {
                  return (_openBlock(), _createBlock(_component_ConfirmationElement, {
                    key: element.id,
                    class: _normalizeClass([{ 'col-span-2': _ctx.doubleWidthFieldIds.includes(element.id) }, "p-2"]),
                    label: `${element.label || ''}${element.required && element.label ? '*' : ''}`
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.formatValue(element)), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "label"]))
                }), 128))
              ], 2))
            }), 128)),
            (_ctx.computedState.notification?.rejected || _ctx.computedState.notification?.approved)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.computedState.notification?.approved ? _ctx.$t('global.forms.steps.confirmation.alreadyApprovedMessage') : _ctx.$t('global.forms.steps.confirmation.alreadyRejectedMessage')), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.config.rejectable && _ctx.computedState.notification?.disabled !== true)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass(!_ctx.computedState.notification?.rejected ? 'col-span-10' : 'col-span-6 order-2')
                  }, [
                    _createVNode(_component_CustomButton, {
                      disabled: _ctx.disableButtons,
                      disable: !_ctx.formValid,
                      onClick: _ctx.onSubmit
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.confirmation.sendButtonLabel')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "disable", "onClick"])
                  ], 2),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_CustomButton, {
                      disabled: _ctx.disableButtons,
                      "color-preset": "secondary",
                      onClick: _ctx.onBackClicked
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.confirmation.editButtonLabel')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  (!_ctx.computedState.notification?.rejected)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_CustomButton, {
                          disabled: _ctx.disableButtons,
                          "color-preset": "warning",
                          onClick: _ctx.onRejectClicked
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.confirmation.rejectButtonLabel')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_ctx.computedState.notification?.disabled !== true)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_CustomButton, {
                        disabled: _ctx.disableButtons,
                        "color-preset": "secondary",
                        onClick: _ctx.onBackClicked
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.config.editButtonLabel || _ctx.$t('global.forms.steps.confirmation.editButtonLabel')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_CustomButton, {
                        loading: _ctx.disableButtons,
                        disabled: !_ctx.formValid,
                        onClick: _ctx.onSubmit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('global.forms.steps.confirmation.sendButtonLabel')), 1)
                        ]),
                        _: 1
                      }, 8, ["loading", "disabled", "onClick"])
                    ])
                  ]))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_ForceSubmissionModal, {
          state: _ctx.state,
          "submission-can-be-forced": _ctx.submissionCanBeForced,
          "format-value": _ctx.formatValue,
          onSubmit: _ctx.onForceSubmissionSubmit,
          onBack: _ctx.onForceSubmissionEdit,
          onClosing: _cache[0] || (_cache[0] = ($event: any) => (_ctx.disableButtons = false))
        }, {
          default: _withCtx(({ scope }) => [
            _createElementVNode("input", {
              ref: "force-submission-modal",
              type: "hidden",
              onClick: scope.open
            }, null, 8, _hoisted_13)
          ]),
          _: 1
        }, 8, ["state", "submission-can-be-forced", "format-value", "onSubmit", "onBack"])
      ])
    ])
  ]))
}
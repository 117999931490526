<template>
    <button type="button" class="min-h-28 bg-white rounded-2xl px-4 flex shadow-md w-full space-x-4 text-left">
        <div class="flex my-auto">
            <Icon class="my-auto h-14 min-w-14" :src="kpiType.icon_url"></Icon>
        </div>
        <div class="flex my-auto flex-1">
            <h4 class="my-auto font-semibold text-lg">
                <span v-html="kpiText"></span>
                <span v-if="kpiType.suffix">{{ ` (${kpiType.suffix})` }}</span>
            </h4>
        </div>
        <transition name="fade">
            <div class="flex justify-end my-auto w-12">
                <div class="relative my-auto h-12 w-12">
                    <span class="absolute my-auto border top-0.5 left-0.5 h-10 w-10 rounded-full border-primary-300"> </span>
                    <transition
                        enter-active-class="transition duration-300"
                        enter-from-class="transform opacity-0"
                        enter-to-class="transform opacity-100"
                        leave-active-class="transition duration-300"
                        leave-from-class="transform opacity-100"
                        leave-to-class="transform opacity-0"
                    >
                        <Icon v-show="selected" class="h-11 w-11 rounded-full" :src="IconSource.Selected"></Icon>
                    </transition>
                </div>
            </div>
        </transition>
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { KpiType } from '@/types';

export default defineComponent({
    props: {
        kpiType: {
            type: Object as () => KpiType,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        kpiText(): string {
            return sanitizeHtml(this.kpiType?.text_wrapped?.replaceAll('##', '&shy;') || this.kpiType?.text || '');
        },
    },
});
</script>

<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.debug.header.heading')" :has-period-picker="true" :has-pig-type-picker="true">
        <div class="space-y-8">
            <MainViewCard class="pt-4 pb-2 px-2">
                <GenericChartWithApi :fetch-action-type="chart.fetchActionType" :chart-name="chart.name" :height="chart.height" :chart-type="chart.type"></GenericChartWithApi>
            </MainViewCard>
            <ActionModal v-if="playgroundModalInitialState.form" :header="{ icon_url: null, text: 'Playground' }" :initial-step-index="1" :initial-state="playgroundModalInitialState">
                <template #default="{ open }">
                    <CustomButton class="w-40" @click="open"> Playground form </CustomButton>
                </template>
            </ActionModal>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import GenericChartWithApi from '@/components/charts/GenericChartWithApi.vue';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { Table, Chart, ChartType } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { DashboardContentLayout, MainViewCard, GenericChartWithApi, CustomButton, ActionModal },
    data() {
        return {
            chart: {
                name: Chart.Debug,
                type: ChartType.ColumnChart,
                height: 400,
                fetchActionType: ActionType.GetDebugChart,
            },
            playgroundModalInitialState: {
                location: null,
                form: null,
                confirmed: false,
                farm: null,
                record_id: null,
                notification: null,
                event: null,
            } as any,
        };
    },
    async created() {
        const form = await store.dispatch(ActionType.GetForm, { form: { form_id: 999 } });
        this.playgroundModalInitialState.form = form;
    },
});
</script>

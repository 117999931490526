<template>
    <Listbox v-model="listboxValue">
        <div v-click-outside="onClickOutside" class="relative">
            <ListboxButton
                class="overflow-hidden relative w-full shadow-none"
                @click="onListboxButtonClicked"
                @focus="buttonFocused = true"
                @blur="buttonFocused = false"
                @keydown.enter="onListboxButtonClicked"
                @keydown.space="onListboxButtonClicked"
            >
                <div>
                    <Icon :src="IconSource.Hamburger"></Icon>
                </div>
            </ListboxButton>
            <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-100 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <div v-show="expandListbox" :class="[dropdownHeightClass]" class="absolute -mt-1 top-full right-0 bg-white border z-30 rounded overflow-y-auto whitespace-nowrap">
                    <ListboxOptions static class="outline-none" @keydown.esc.stop="expandListbox = false">
                        <ListboxOption v-for="(item, index) in filteredItems" :key="item[itemValue]" v-slot="{ active }" :value="item">
                            <button type="button" :class="[itemSelection[index] ? '' : 'hover:bg-gray-100', active ? 'bg-gray-100' : '']" class="p-3 h-full text-left w-full cursor-pointer flex">
                                <span class="flex-1">
                                    {{ item[itemText] }}
                                </span>
                                <span class="my-auto justify-self-end ml-2">
                                    <CustomCheckbox :checked="itemSelection[index]" @update:checked="listboxValue = item"></CustomCheckbox>
                                </span>
                            </button>
                        </ListboxOption>
                    </ListboxOptions>
                </div>
            </transition>
        </div>
    </Listbox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import ClickOutside from '@makeabledk/vue-ui/directives/click-outside';
import ExpandIcon from '@/components/icons/ExpandIcon.vue';
import { validatableField } from '@/mixins/validatableField';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';

export default defineComponent({
    directives: {
        ClickOutside,
    },
    components: {
        Listbox,
        ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        ExpandIcon,
        CustomInput,
        CustomCheckbox,
    },
    mixins: [validatableField],
    emits: ['update:value', 'input', 'closing'],
    props: {
        value: {
            default: [] as any[],
        },
        items: {
            type: Array as () => any[],
            default: [],
        },
        itemText: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
        dropdownHeightClass: {
            type: String,
            default: 'max-h-64',
        },
    },
    data() {
        return {
            listboxValue: null,
            expandListbox: false,
            buttonFocused: false,
        };
    },
    computed: {
        itemSelection(): boolean[] {
            return this.filteredItems.map((currentItem: any) => Boolean(this.computedValue.find((currentValue) => currentValue[this.$props.itemValue] === currentItem[this.$props.itemValue])));
        },
        filteredItems() {
            return this.$props.items.filter((currentItem) => currentItem !== null);
        },
        computedValue: {
            get(): any[] {
                return this.value === null ? [] : this.value;
            },
            set(newValue: any[]) {
                this.$emit('update:value', newValue);
            },
        },
    },
    watch: {
        listboxValue(newValue: any) {
            if (newValue) {
                const indexOfNew = this.computedValue.findIndex((currentValue) => currentValue[this.$props.itemValue] === newValue[this.$props.itemValue]);
                if (indexOfNew >= 0) {
                    this.computedValue.splice(indexOfNew, 1);
                } else {
                    this.computedValue.push(newValue);
                }

                this.listboxValue = null;
            }
        },
        expandListbox(newValue: boolean) {
            if (!newValue) {
                this.$emit('closing');
            }
        },
        computedValue() {
            this.$emit('input');
        },
    },
    created() {
        if (this.value === null) {
            this.computedValue = [];
        }
    },
    methods: {
        onClickOutside() {
            this.expandListbox = false;
        },
        onListboxButtonClicked() {
            this.expandListbox = !this.expandListbox;
        },
    },
});
</script>

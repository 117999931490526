import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff70bd6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inline-block text-left w-full h-full" }
const _hoisted_2 = { class: "relative h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrapFocus = _resolveComponent("TrapFocus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.slotContainerClass, "w-full h-full"])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2),
      _createVNode(_component_TrapFocus, {
        disabled: !_ctx.computedExpand
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            "enter-active-class": "transform transition ease-out duration-100",
            "enter-from-class": "transform opacity-0 scale-0",
            "enter-to-class": "transform opacity-100 scale-100",
            "leave-active-class": "transition ease-in duration-100",
            "leave-class": "transform opacity-100 scale-100",
            "leave-to-class": "transform opacity-0 scale-0",
            appear: ""
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", null, [
                _createElementVNode("div", {
                  ref: "init-focus",
                  tabindex: "0",
                  onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.computedExpand = false), ["esc"]))
                }, null, 544),
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.dropdownClass, { 'z-40': _ctx.computedExpand }], "origin-top-right right-0 absolute rounded-md shadow-lg bg-white"]),
                  role: "menu",
                  "aria-orientation": "vertical",
                  "aria-labelledby": "options-menu"
                }, [
                  _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, "dropdown-header", {}, undefined, true)
                  ]),
                  _createElementVNode("div", {
                    class: "divide-y divide-gray-100 overflow-y-auto",
                    style: _normalizeStyle(_ctx.dropdownContentStyle)
                  }, [
                    _renderSlot(_ctx.$slots, "dropdown-content", {}, undefined, true)
                  ], 4)
                ], 2)
              ], 512), [
                [_vShow, _ctx.computedExpand]
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["disabled"])
    ]),
    _withDirectives(_createElementVNode("div", {
      style: {"background-color":"rgba(0, 0, 0, 0.1)"},
      class: "cursor-default z-30 fixed top-0 left-0 bottom-0 right-0",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.computedExpand = false))
    }, null, 512), [
      [_vShow, _ctx.computedExpand]
    ]),
    (_ctx.topbarOverlay)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          style: {"background-color":"rgba(0, 0, 0, 0.1)","z-index":"1041"},
          class: "cursor-default h-24 fixed top-0 left-0 right-0",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.computedExpand = false))
        }, null, 512)), [
          [_vShow, _ctx.computedExpand]
        ])
      : _createCommentVNode("", true)
  ]))
}
<template>
    <div class="w-[270px] h-full bg-primary-700 cursor-pointer">
        <DropDown v-model:expand="expandDropdown" dropdown-class="w-96 !origin-top-left !right-auto -mt-1 overflow-y-auto max-h-160">
            <template #dropdown-header>
                <div class="pb-2 pt-4 px-4 cursor-default flex-col space-y-2">
                    <SearchInput v-model:value="search" :label="$t('layouts.dashboard.topBar.locationPicker.searchFieldLabel')" @keydown.esc.prevent="search = ''"></SearchInput>
                    <div class="flex space-x-2">
                        <button class="bg-primary-300 transition-width text-primary-800 font-medium text-sm py-2 px-2 rounded whitespace-nowrap w-7/12" @click="toggleExpandFarms">
                            {{ allFarmsAreExpanded ? $t('layouts.dashboard.topBar.locationPicker.collapseAllFarmsButtonLabel') : $t('layouts.dashboard.topBar.locationPicker.expandAllFarmsButtonLabel') }}
                        </button>
                        <button class="bg-primary-300 transition-width text-primary-800 font-medium text-sm py-2 px-2 rounded whitespace-nowrap w-5/12" @click="toggleAllLocationsSelected">
                            {{ allLocationsAreSelected ? $t('layouts.dashboard.topBar.locationPicker.deselectAllButtonLabel') : $t('layouts.dashboard.topBar.locationPicker.selectAllButtonLabel') }}
                        </button>
                    </div>
                </div>
            </template>
            <template #dropdown-content>
                <div v-if="locationPickerState" class="flex flex-col divide-y">
                    <NestedLocation
                        v-for="farm of locationPickerState"
                        :ref="addNestedLocationToRefs"
                        :key="farm.id"
                        :location-picker-state="locationPickerState"
                        :option="farm"
                        :path="`${farm.id}`"
                        :nested="isNested"
                        class="text-left flex"
                        @location-toggled="onLocationToggled"
                        @toggle-expand="onFarmToggleExpand"
                    ></NestedLocation>
                </div>
            </template>

            <button type="button" class="h-full w-full flex px-4 py-4 text-left" @click="toggleExpandDropdown">
                <div class="w-full h-full flex">
                    <div class="w-full flex space-x-2">
                        <div class="my-auto">
                            <Icon class="min-w-7" :src="IconSource.SelectFarms"></Icon>
                        </div>
                        <div class="flex flex-col pt-1 my-auto">
                            <div v-if="user" class="text-white text-base align-self-end max-h-12 overflow-y-hidden">
                                {{ user.directory_name }}
                            </div>
                            <div class="text-white opacity-50 text-sm">
                                {{ $t('layouts.dashboard.topBar.locationPicker.selectedSites').replace('$NUMBER_OF_SELECTED_SITES', `${locallySelectedIds.farms.length}`) }}
                            </div>
                        </div>
                    </div>
                    <div class="content-center justify-end grid">
                        <ExpandIcon color="white" :expand="expandDropdown"></ExpandIcon>
                    </div>
                </div>
            </button>
        </DropDown>
    </div>
</template>

<script lang="ts">
import { Ref, computed, defineComponent, ref, watch } from 'vue';
import DropDown from '@/components/common/DropDown.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import ExpandIcon from '@/components/icons/ExpandIcon.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Barn, Batch, PigType, Section } from '@/types';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import useFilterableLocations from '@/components/common/composables/useFilterableLocations';
import SearchInput from '@/components/ui/SearchInput.vue';
import NestedLocation from '@/components/layouts/dashboardLayout/topBar/locationPicker/NestedLocation.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import { MutationType } from '@/plugins/store/mutations';
import useDebouncedRef from '@/components/common/composables/useDebouncedRef';

export default defineComponent({
    components: { DropDown, CustomCheckbox, ExpandIcon, ExpandCollapseTransition, SearchInput, NestedLocation },
    setup() {
        store.dispatch(ActionType.GetUser, { options: { skipLanguageCheck: true } }).then(() => {
            store.dispatch(ActionType.GetSections);
        });
        store.dispatch(ActionType.GetFarms);
        store.dispatch(ActionType.GetBarns);
        const user = computed(() => store.state.user);

        const locallySelectedIds = ref({
            farms: [] as number[],
            barns: [] as number[],
            batches: [] as number[],
            sections: [] as number[],
        });
        const availableBatchIds = computed(() => store.state.barns.filter((b) => b.is_batch_production).flatMap((b) => b.open_batches?.map((batch) => batch.batch_id) || []));
        const availableSectionIds = computed(() => store.state.sections?.filter((s) => store.state.barns.some((b) => b.barn_id === s.barn_id)).map((s) => s.id) || []);
        const allLocationsAreSelected = computed(
            () =>
                locallySelectedIds.value.farms.length === store.state.farms.length &&
                locallySelectedIds.value.barns.length === store.state.barns.length &&
                locallySelectedIds.value.batches.length === availableBatchIds.value.length &&
                locallySelectedIds.value.sections.length === availableSectionIds.value.length
        );
        const toggleAllLocationsSelected = () => {
            if (allLocationsAreSelected.value) {
                locallySelectedIds.value = {
                    farms: [],
                    barns: [],
                    batches: [],
                    sections: [],
                };
            } else {
                locallySelectedIds.value = {
                    farms: store.state.farms.map((farm) => farm.farm_id),
                    barns: store.state.barns.map((barn) => barn.barn_id),
                    batches: availableBatchIds.value,
                    sections: availableSectionIds.value,
                };
            }
        };
        watch(
            () => store.state.selectedFarmIds,
            (newValue) => {
                locallySelectedIds.value.farms = [...newValue];
            },
            { deep: true, immediate: true }
        );
        watch(
            () => store.state.selectedBarnIds,
            (newValue) => {
                locallySelectedIds.value.barns = [...newValue];
            },
            { deep: true, immediate: true }
        );
        watch(
            () => store.state.selectedBatchIds,
            (newValue) => {
                locallySelectedIds.value.batches = [...newValue];
            },
            { deep: true, immediate: true }
        );
        watch(
            () => store.state.selectedSectionIds,
            (newValue) => {
                locallySelectedIds.value.sections = [...newValue];
            },
            { deep: true, immediate: true }
        );

        const locations = computed(() => store.getters.getLocations(true, true, false));

        const search = useDebouncedRef('', 200, false);
        const { filteredFarms } = useFilterableLocations(search, locations, { farms: ['name'], locations: ['name'], sections: ['name'] });

        const locationPickerState = computed(() => {
            function getSectionChilds(sections: Section[] | undefined, barnId: number) {
                return sections?.map((s) => ({
                    ...s,
                    id: s.id,
                    barn_id: barnId,
                    title: s.name,
                    isSection: true,
                    subtitle: `${s.pigs} ${i18n.global.t('layouts.dashboard.topBar.locationPicker.subtitles.pigs')}`,
                    selected: locallySelectedIds.value.sections.includes(s.id),
                }));
            }

            function getBatchChilds(batches: Batch[] | undefined, barnId: number) {
                return batches?.map((b) => ({
                    ...b,
                    id: b.batch_id,
                    barn_id: barnId,
                    title: b.name,
                    isBatch: true,
                    subtitle: `${b.pigs} ${i18n.global.t('layouts.dashboard.topBar.locationPicker.subtitles.pigs')}`,
                    selected: locallySelectedIds.value.batches.includes(b.batch_id),
                }));
            }

            function getLocationSubtitle(location: Batch | Barn) {
                if ((location as Barn).open_batches?.length) {
                    const selectedBatchCount = (location as Barn).open_batches.filter((b) => locallySelectedIds.value.batches.includes(b.batch_id)).length.toString() || '0';
                    const totalBatchCount = (location as Barn).open_batches.length.toString();

                    return i18n.global
                        .t('layouts.dashboard.topBar.locationPicker.subtitles.batchLocation')
                        .replace('$NUMBER_OF_BATCHES', totalBatchCount)
                        .replace('$NUMBER_OF_SELECTED_BATCHES', selectedBatchCount)
                        .replace('$NUMBER_OF_PIGS', location.pigs.toString());
                }

                if ((location as Barn).sections?.length) {
                    const selectedSectionCount = (location as Barn).sections?.filter((s) => locallySelectedIds.value.sections.includes(s.id)).length.toString() || '0';
                    const totalSectionCount = (location as Barn).sections?.length.toString() || '0';

                    return i18n.global
                        .t('layouts.dashboard.topBar.locationPicker.subtitles.sectionLocation')
                        .replace('$NUMBER_OF_SECTIONS', totalSectionCount)
                        .replace('$NUMBER_OF_SELECTED_SECTIONS', selectedSectionCount)
                        .replace('$NUMBER_OF_PIGS', location.pigs.toString());
                }

                return `${location.pigs} ${i18n.global.t('layouts.dashboard.topBar.locationPicker.subtitles.pigs')}`;
            }

            function getFarmLocations(locations: (Batch | Barn)[] | undefined) {
                return locations?.map((location) => ({
                    id: location.barn_id,
                    is_batch_production: (location as Barn).is_batch_production ?? false,
                    is_weaners: (location as Barn).is_weaners ?? false,
                    title: location.name,
                    isLocation: true,
                    subtitle: getLocationSubtitle(location),
                    selected: locallySelectedIds.value.barns.includes((location as Barn).barn_id),
                    children:
                        (location as Barn).is_batch_production && (location as Barn).open_batches?.length
                            ? getBatchChilds((location as Barn).open_batches, (location as Barn).barn_id)
                            : getSectionChilds((location as Barn).sections, (location as Barn).barn_id),
                }));
            }

            return filteredFarms.value.reduce((accum, farm) => {
                const totalLocationCount = farm.locations?.length.toString() || '0';
                const selectedLocationCount = locallySelectedIds.value.barns.filter((id) => farm.locations?.map((b) => b.barn_id).includes(id)).length.toString() || '0';
                const subtitle = farm.locations?.length
                    ? i18n.global.t('layouts.dashboard.topBar.locationPicker.subtitles.farm').replace('$NUMBER_OF_LOCATIONS', totalLocationCount).replace('$NUMBER_OF_SELECTED_LOCATIONS', selectedLocationCount)
                    : '';

                accum[farm.farm_id] = {
                    id: farm.farm_id,
                    title: farm.name,
                    chr: farm.public_id?.ids || [],
                    isFarm: true,
                    subtitle,
                    selected: locallySelectedIds.value.farms.includes(farm.farm_id),
                    children: getFarmLocations(farm.locations),
                };
                return accum;
            }, {} as { [key: string]: any });
        });
        const isNested = computed(() => Object.values(locationPickerState.value).some((c: any) => c?.children?.length));

        const onLocationToggled = ({ level, option }: { level: number; option: any }) => {
            switch (level) {
                // Barn
                case 1: {
                    if (locallySelectedIds.value.barns.includes(option.id)) {
                        locallySelectedIds.value.barns = locallySelectedIds.value.barns.filter((id) => id !== option.id);

                        if (option.children?.length) {
                            if (option.is_batch_production) {
                                const batchIds = option.children?.map((b: Batch) => b.batch_id) || [];

                                locallySelectedIds.value.batches = locallySelectedIds.value.batches.filter((id) => !batchIds.includes(id));
                            } else {
                                const sectionIds: number[] = option.children.map((s: Section) => s.id);

                                locallySelectedIds.value.sections = locallySelectedIds.value.sections.filter((id) => !sectionIds.includes(id));
                            }
                        }
                    } else {
                        locallySelectedIds.value.barns.push(option.id);

                        if (option.children?.length) {
                            if (option.is_batch_production) {
                                const batchIds = option.children?.filter((b: Batch) => b.is_active).map((b: Batch) => b.batch_id) || [];
                                const addedBatchIds = batchIds.filter((id: number) => !locallySelectedIds.value.batches.includes(id));

                                locallySelectedIds.value.batches.push(...addedBatchIds);
                            } else {
                                const sectionIds: number[] = option.children.map((s: Section) => s.id);
                                const addedSectionIds = sectionIds.filter((id) => !locallySelectedIds.value.sections.includes(id));

                                locallySelectedIds.value.sections.push(...addedSectionIds);
                            }
                        }
                    }

                    break;
                }
                // Batch or section
                case 2: {
                    if ((option as Batch).batch_id !== undefined) {
                        if (locallySelectedIds.value.batches.includes((option as Batch).batch_id)) {
                            locallySelectedIds.value.batches = locallySelectedIds.value.batches.filter((id) => id !== (option as Batch).batch_id);
                        } else {
                            locallySelectedIds.value.batches.push((option as Batch).batch_id);
                        }
                    } else if (locallySelectedIds.value.sections.includes(option.id)) {
                        locallySelectedIds.value.sections = locallySelectedIds.value.sections.filter((id) => id !== option.id);
                    } else {
                        locallySelectedIds.value.sections.push(option.id);
                    }

                    break;
                }
                // Farm
                default: {
                    if (locallySelectedIds.value.farms.includes(option.id)) {
                        locallySelectedIds.value.farms = locallySelectedIds.value.farms.filter((id) => id !== option.id);

                        if (option.children?.length) {
                            const filteredBarnIds: number[] = [];
                            const filteredBatchIds: number[] = [];
                            const filteredSectionIds: number[] = [];

                            option.children.forEach((barn: any) => {
                                filteredBarnIds.push(barn.id);

                                if (barn.children?.length) {
                                    if (barn.is_batch_production) {
                                        const batchIds = barn.children.map((b: Batch) => b.batch_id);

                                        filteredBatchIds.push(...batchIds);
                                    } else {
                                        const sectionIds = barn.children.map((s: Section) => s.id);

                                        filteredSectionIds.push(...sectionIds);
                                    }
                                }
                            });

                            locallySelectedIds.value.barns = locallySelectedIds.value.barns.filter((id) => !filteredBarnIds.includes(id));
                            locallySelectedIds.value.batches = locallySelectedIds.value.batches.filter((id) => !filteredBatchIds.includes(id));
                            locallySelectedIds.value.sections = locallySelectedIds.value.sections.filter((id) => !filteredSectionIds.includes(id));
                        }
                    } else {
                        locallySelectedIds.value.farms.push(option.id);

                        if (option.children?.length) {
                            const addedBarnIds: number[] = [];
                            const addedBatchIds: number[] = [];
                            const addedSectionIds: number[] = [];

                            option.children.forEach((barn: any) => {
                                if (barn.is_weaners !== (store.state.pigType === PigType.Weaners)) {
                                    return;
                                }

                                addedBarnIds.push(barn.id);

                                if (barn.children?.length) {
                                    if (barn.is_batch_production) {
                                        const batchIds: number[] = barn.children.filter((b: Batch) => b.is_active).map((b: Batch) => b.batch_id);
                                        const batchIdsToAdd = batchIds.filter((id) => !locallySelectedIds.value.batches.includes(id));

                                        addedBatchIds.push(...batchIdsToAdd);
                                    } else {
                                        const sectionIds: number[] = barn.children.map((s: Section) => s.id);
                                        const sectionIdsToAdd = sectionIds.filter((id) => !locallySelectedIds.value.sections.includes(id));

                                        addedSectionIds.push(...sectionIdsToAdd);
                                    }
                                }
                            });

                            locallySelectedIds.value.barns.push(...addedBarnIds);
                            locallySelectedIds.value.sections.push(...addedSectionIds);
                            locallySelectedIds.value.batches.push(...addedBatchIds);
                        }
                    }
                    break;
                }
            }
        };

        const expandDropdown = ref(false);
        const toggleExpandDropdown = () => {
            expandDropdown.value = !expandDropdown.value;
        };
        watch(expandDropdown, (newValue) => {
            if (!newValue) {
                store.commit(MutationType.SetSelectedFarmIds, locallySelectedIds.value.farms);
                store.commit(MutationType.SetSelectedBarnIds, locallySelectedIds.value.barns);
                store.commit(MutationType.SetSelectedBatchIds, locallySelectedIds.value.batches);
                store.commit(MutationType.SetSelectedSectionIds, locallySelectedIds.value.sections);
            }
        });

        const nestedLocationRefs: Ref<{ [key: string]: any }> = ref({});
        const addNestedLocationToRefs = (reference: { [key: string]: any; path: string } | null) => {
            if (reference) {
                nestedLocationRefs.value[reference.path] = reference;
            }
        };
        const allFarmsAreExpanded = ref(false);
        const onFarmToggleExpand = () => {
            allFarmsAreExpanded.value = Object.keys(nestedLocationRefs.value).every((key) => nestedLocationRefs.value[key].expand || !nestedLocationRefs.value[key].option?.children?.length);
        };
        const toggleExpandFarms = () => {
            const expand = !allFarmsAreExpanded.value;
            Object.keys(nestedLocationRefs.value).forEach((key) => {
                if (nestedLocationRefs.value[key].option?.children?.length) {
                    nestedLocationRefs.value[key].expand = expand;
                }
            });
        };

        return {
            filteredLocations: filteredFarms,
            expandDropdown,
            search,
            locallySelectedIds,
            user,
            toggleAllLocationsSelected,
            toggleExpandDropdown,
            locationPickerState,
            onLocationToggled,
            allLocationsAreSelected,
            addNestedLocationToRefs,
            allFarmsAreExpanded,
            onFarmToggleExpand,
            toggleExpandFarms,
            isNested,
        };
    },
});
</script>

<template>
    <div class="min-h-20 bg-white rounded-2xl py-4 px-4 grid shadow-md w-full overflow-hidden">
        <div class="flex space-x-4 w-full">
            <div class="my-auto min-w-14">
                <Icon v-if="kpiType?.icon_url" class="w-14 h-14" :src="kpiType?.icon_url"></Icon>
            </div>
            <div class="my-auto overflow-hidden w-full">
                <div class="flex space-x-6 h-8">
                    <span class="text-xl font-semibold tracking-tighter truncate pr-1">
                        {{ kpiValue }}
                    </span>
                </div>
                <div class="max-w-full">
                    <span class="text-gray-600 text-lg max-w-full" v-html="kpiText"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { Kpi, KpiType } from '@/types';
import { store } from '@/plugins/store';

export default defineComponent({
    props: {
        kpi: {
            type: Object as () => Kpi,
            required: true,
        },
    },
    computed: {
        kpiType(): KpiType | undefined {
            return store.state.kpiTypes.find((kpiType) => kpiType.id === this.kpi.type_id);
        },
        kpiValue(): string {
            if (this.kpi.value === null) {
                return '-';
            }
            let value = this.kpiType?.prefix ? `${this.kpiType?.prefix} ` : '';
            value += typeof this.kpi.value === 'number' ? this.$n(this.kpi.value) : this.kpi.value;
            value += this.kpiType?.suffix ? ` ${this.kpiType?.suffix}` : '';
            return value;
        },
        kpiText(): string {
            return sanitizeHtml(this.kpiType?.text_wrapped?.replaceAll('##', '&shy;') || this.kpiType?.text || '');
        },
    },
});
</script>

<template>
    <DashboardContentLayout class="space-y-8" :heading="$t('views.settings.directories.header.heading')" :has-period-picker="false" :has-pig-type-picker="false">
        <DirectoriesTable></DirectoriesTable>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import DirectoriesTable from '@/views/dashboard/settings/directories/DirectoriesTable.vue';

export default defineComponent({
    components: { DashboardContentLayout, DirectoriesTable },
});
</script>

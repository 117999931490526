import { computed, ComputedRef, isRef } from 'vue';
import { Options } from 'highcharts';
import merge from 'lodash-es/merge';
import { i18n } from '@/plugins/internationalization/i18n';
import colors from '@/plugins/tailwind';
import { store } from '@/plugins/store';

export default function useDefaultChartOptions(chartInstanceOptions: any, chartTypeOptions: any) {
    const unwrappedChartOptions = isRef(chartTypeOptions) ? chartTypeOptions.value : chartTypeOptions;
    const periodConstituents = store.state.periodPickerDateString.split(' - ');
    let min: null | number = null;
    let max: null | number = null;
    if (periodConstituents.length === 2) {
        min = new Date(periodConstituents[0]).setHours(0, 0, 0, 0);
        max = new Date(periodConstituents[1]).setHours(23, 59, 59, 999);
    }

    const options = computed(() =>
        merge(
            {
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        fontWeight: 500,
                    },
                },
                colors: [colors.primary[800], colors.deaths, colors.feed[400], colors.pigs, '#2f73a4', '#e8b24a'],
                yAxis: {
                    labels: {
                        style: {
                            fontSize: '0.75rem',
                        },
                    },
                    title: {
                        text: '',
                    },
                    opposite: true,
                    gridLineDashStyle: 'Dash',
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%W.%y}',
                        style: {
                            fontSize: '0.75rem',
                        },
                        rotation: -90,
                    },
                    min,
                    max,
                    title: {
                        text: '',
                    },
                    /* a tick per week */
                    tickInterval: 1000 * 60 * 60 * 24 * 7,
                    tickLength: 0,
                },
                series: [],
                chart: {
                    style: {
                        fontFamily:
                            '"Noto Sans", "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
                        fontSize: '1rem',
                    },
                    marginRight: 48,
                },
                plotOptions: {
                    series: {
                        borderRadius: 5,
                        tooltip: {
                            headerFormat: '',
                            pointFormatter() {
                                return `<span style="font-size: 10px">${i18n.global.d((this as any).x, 'middle')}</span><br/><span style="color:${(this as any).color}">●</span> ${
                                    (this as any).series.name
                                }: <b>${i18n.global.n((this as any).y)}</b><br/>`;
                            },
                        },
                    },
                },
                credits: {
                    enabled: false,
                },
                navigation: {
                    buttonOptions: {
                        enabled: false,
                    },
                },
            },
            unwrappedChartOptions,
            chartInstanceOptions
        )
    );

    return options as any as ComputedRef<Options>;
}

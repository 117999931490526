<template>
    <main class="w-full py-8 px-6 mx-auto" style="min-height: calc(100vh - 12rem)">
        <DashboardContentHeader :heading="heading" :has-period-picker="hasPeriodPicker" :has-pig-type-picker="hasPigTypePicker">
            <template #top-right><slot name="header-top-right"></slot></template>
        </DashboardContentHeader>
        <slot></slot>
    </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardContentHeader from '@/components/layouts/dashboardLayout/content/DashboardContentHeader.vue';

export default defineComponent({
    components: { DashboardContentHeader },
    props: {
        heading: {
            type: String,
            default: '',
        },
        hasPigTypePicker: {
            type: Boolean,
            default: false,
        },
        hasPeriodPicker: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<template>
    <div role="alert" class="inline-flex items-center text-gray-900 py-1 pl-1.5 pr-2.5 rounded-full font-medium" :class="typeClasses">
        <Icon :src="iconSrc" class="w-6 h-6 mr-1.5"></Icon>
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { IconSource } from '@/types';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: 'success',
        },
    },
    setup(props) {
        const typeClasses = computed(() => [
            {
                'bg-[#C0F3D3]': props.type === 'success',
                'bg-[#FDDBD5]': props.type === 'error',
                'bg-[#FBE6C6]': props.type === 'warning',
            },
        ]);

        const iconSrc = computed(() => {
            switch (props.type) {
                case 'error':
                    return IconSource.AlarmIcon;
                case 'warning':
                    return IconSource.AttentionIcon;
                case 'success':
                default:
                    return IconSource.OKIcon;
            }
        });

        return {
            typeClasses,
            IconSource,
            iconSrc,
        };
    },
});
</script>

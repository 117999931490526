<template>
    <div>
        <div class="assignment-tabs default-tabs">
            <Tabs teleport="#monitoring-tabs-target" active-tab-class="--active">
                <nav class="space-x-8 flex border-b border-black border-opacity-10">
                    <div class="flex pr-4">
                        <Tab :name="$t('views.monitoring.tabTitles.overview')" :default="true">
                            <OverviewTab :items="filteredItems" />
                        </Tab>
                    </div>
                    <div class="flex pr-4">
                        <Tab :name="$t('views.monitoring.tabTitles.water')">
                            <WaterTab :items="filteredItems" />
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.monitoring.tabTitles.food')">
                            <FoodTab :items="filteredItems" />
                        </Tab>
                    </div>
                    <div class="flex">
                        <Tab :name="$t('views.monitoring.tabTitles.weight')">
                            <WeightTab :items="filteredItems" />
                        </Tab>
                    </div>
                </nav>
            </Tabs>
            <div id="monitoring-tabs-target" class="mt-6" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { Tab, Tabs } from '@makeabledk/vue-ui/components/tabs';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { MonitoringDataBarn, MonitoringDataHeaders } from '@/types';
import OverviewTab from './overview/OverviewTab.vue';
import WaterTab from './water/WaterTab.vue';
import FoodTab from './food/FoodTab.vue';
import WeightTab from './weight/WeightTab.vue';

export interface MonitoringDataItem {
    farm_id: number;
    farm_name?: string | null;
    headers: MonitoringDataHeaders;
    barns: MonitoringDataBarn[];
    sectionCount?: number;
}

export default defineComponent({
    components: { Tab, Tabs, OverviewTab, WaterTab, FoodTab, WeightTab },
    props: {
        searchQuery: {
            type: String as () => string,
            default: '',
        },
    },
    setup(props) {
        useRefetchDataOnDashboardStateChanged(ActionType.GetMonitoringData, { watchSites: true, watchFormSubmissions: true });

        function getSectionCount(barns: any) {
            if (!barns) return 0;

            let sectionCount = 0;

            for (const barn of barns) {
                sectionCount += barn.rows.length;
            }

            return sectionCount;
        }

        const items = computed<MonitoringDataItem[]>(() => {
            if (!store.state.monitoringData) {
                return [];
            }

            const mappedItems: {
                farm_id: number;
                farm_name?: string | null;
                barn_id: number;
                headers: MonitoringDataHeaders;
                barns: MonitoringDataBarn[];
                sectionCount?: number;
            }[] = [];

            for (const barn of store.state.monitoringData) {
                const existingFarm = mappedItems.find((i: any) => i.farm_id === barn.headers.farm_id);

                if (existingFarm) {
                    existingFarm.barns.push(barn);
                } else {
                    mappedItems.push({
                        farm_id: barn.headers.farm_id,
                        farm_name: barn.headers.farm_name,
                        barn_id: barn.headers.barn_id,
                        headers: barn.headers,
                        barns: [barn],
                    });
                }
            }

            for (const item of mappedItems) {
                item.sectionCount = getSectionCount(item.barns);
                item.barns.sort((a, b) => {
                    const aRows = a.rows;
                    const bRows = b.rows;

                    const aHasValidRows = Array.isArray(aRows) && aRows.length > 0;
                    const bHasValidRows = Array.isArray(bRows) && bRows.length > 0;

                    if (!aHasValidRows && bHasValidRows) {
                        return 1;
                    }
                    if (aHasValidRows && !bHasValidRows) {
                        return -1;
                    }
                    return 0;
                });
            }

            return mappedItems.sort((a, b) => {
                const aCount = typeof a.sectionCount === 'undefined' || a.sectionCount === 0 ? -1 : a.sectionCount;
                const bCount = typeof b.sectionCount === 'undefined' || b.sectionCount === 0 ? -1 : b.sectionCount;

                if (aCount === -1 && bCount !== -1) {
                    return 1;
                }
                if (aCount !== -1 && bCount === -1) {
                    return -1;
                }
                return aCount - bCount;
            });
        });
        const filteredItems = computed(() => {
            if (!props.searchQuery) {
                return items.value;
            }

            const lowerCaseSearchQuery = props.searchQuery.toLowerCase();

            return items.value.filter(
                (i) =>
                    i.farm_name?.toLowerCase().includes(lowerCaseSearchQuery) ||
                    i.barns.some(
                        (b: MonitoringDataBarn) => b.headers.barn_name?.toLowerCase().includes(lowerCaseSearchQuery) || b.rows?.some((r) => r.section_name?.toLowerCase().includes(lowerCaseSearchQuery))
                    )
            );
        });

        return {
            items,
            filteredItems,
        };
    },
});
</script>

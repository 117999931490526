import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-8 flex flex-col h-full" }
const _hoisted_2 = { class: "text-2xl font-semibold text-center" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "flex space-x-4 flex-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.directories.content.modals.switchConfirmationModal.header')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('views.settings.directories.content.modals.switchConfirmationModal.confirmationText').replace('$DIRECTORY', _ctx.config.directory.name)), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CustomButton, {
        "color-preset": "secondary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.directories.content.modals.switchConfirmationModal.cancelButtonLabel')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        onClick: _ctx.onConfirmButtonClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.directories.content.modals.switchConfirmationModal.confirmationButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}
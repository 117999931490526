import * as Highcharts from 'highcharts';
import * as Dashboards from '@highcharts/dashboards';
import DataGrid from '@highcharts/dashboards/es-modules/DataGrid/DataGrid';
import exportModule from 'highcharts/modules/exporting';
import offlineExportingModule from 'highcharts/modules/offline-exporting';
import stockModule from 'highcharts/modules/stock';
import annotationsModule from 'highcharts/modules/annotations';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDashboardPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/HighchartsPlugin';
import DataGridPlugin from '@highcharts/dashboards/es-modules/Dashboards/Plugins/DataGridPlugin';
import { i18n } from '@/plugins/internationalization/i18n';

Highcharts.setOptions({
    lang: {
        numericSymbols: [null, 'M', 'G', 'T', 'P', 'E'] as any as string[],
        months: [
            i18n.global.t('layouts.dashboard.charts.months.january'),
            i18n.global.t('layouts.dashboard.charts.months.february'),
            i18n.global.t('layouts.dashboard.charts.months.march'),
            i18n.global.t('layouts.dashboard.charts.months.april'),
            i18n.global.t('layouts.dashboard.charts.months.may'),
            i18n.global.t('layouts.dashboard.charts.months.june'),
            i18n.global.t('layouts.dashboard.charts.months.july'),
            i18n.global.t('layouts.dashboard.charts.months.august'),
            i18n.global.t('layouts.dashboard.charts.months.september'),
            i18n.global.t('layouts.dashboard.charts.months.october'),
            i18n.global.t('layouts.dashboard.charts.months.november'),
            i18n.global.t('layouts.dashboard.charts.months.december'),
        ],
    },
});

HighchartsMore(Highcharts);
exportModule(Highcharts);
stockModule(Highcharts);
annotationsModule(Highcharts);
offlineExportingModule(Highcharts);

HighchartsDashboardPlugin.custom.connectHighcharts(Highcharts);
// @ts-ignore
Dashboards.PluginHandler.addPlugin(HighchartsDashboardPlugin);

DataGridPlugin.custom.connectDataGrid(DataGrid);
// @ts-ignore
Dashboards.PluginHandler.addPlugin(DataGridPlugin);

/* eslint-disable no-import-assign */
/* @ts-ignore */
Highcharts.dateFormats = {
    /* @ts-ignore */
    W(timestamp: number) {
        const date = new Date(timestamp);
        const day = date.getUTCDay() === 0 ? 7 : date.getUTCDay();
        date.setDate(date.getUTCDate() + 4 - day);
        const dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6).getTime()) / 86400000);
        return 1 + Math.floor(dayNumber / 7);
    },
    Z(timestamp: number) {
        const date = new Date(timestamp);
        const day = date.getUTCDay() === 0 ? 7 : date.getUTCDay();
        date.setDate(date.getUTCDate() + 4 - day);
        const dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6).getTime()) / 86400000);
        return `${1 + Math.floor(dayNumber / 7)}.${date.getUTCFullYear() % 100}`;
    },
};

import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full grid" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericChartModal = _resolveComponent("GenericChartModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.expandable)
      ? (_openBlock(), _createBlock(_component_GenericChartModal, {
          key: 0,
          "chart-module": _ctx.chartModule,
          options: _ctx.options,
          "print-subtitle": _ctx.printSubtitle
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                ref: "chartElement",
                class: _normalizeClass(["w-full", {
                            'highcharts-dashboards-container': _ctx.options?.value?.type === _ctx.ChartType.Dashboard,
                            'cursor-pointer': !(_ctx.options?.value?.type === _ctx.ChartType.Stock || _ctx.options?.value?.type === _ctx.ChartType.Dashboard),
                        }]),
                onClick: (event) => _ctx.onChartClicked(event, scope.open)
              }, null, 10, _hoisted_3)
            ])
          ]),
          _: 1
        }, 8, ["chart-module", "options", "print-subtitle"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref: "chartElement",
          class: _normalizeClass(["w-full", { 'highcharts-dashboards-container': _ctx.options?.value?.type === _ctx.ChartType.Dashboard }])
        }, null, 2))
  ]))
}
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6cd27ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 0,
  class: "text-black"
}
const _hoisted_4 = { class: "absolute -mt-4 top-full left-0 w-full bg-white border z-30 rounded" }
const _hoisted_5 = {
  key: 0,
  class: "text-base relative cursor-default select-none p-3 text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComboboxInput = _resolveComponent("ComboboxInput")!
  const _component_ComboboxLabel = _resolveComponent("ComboboxLabel")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_ComboboxButton = _resolveComponent("ComboboxButton")!
  const _component_ComboboxOption = _resolveComponent("ComboboxOption")!
  const _component_ComboboxOptions = _resolveComponent("ComboboxOptions")!
  const _component_Combobox = _resolveComponent("Combobox")!

  return (_openBlock(), _createBlock(_component_Combobox, {
    modelValue: _ctx.computedModelValue,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedModelValue) = $event)),
    nullable: ""
  }, {
    default: _withCtx(({ open }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ComboboxInput, _mergeProps({
            "display-value": _ctx.computedDisplayValue,
            autocomplete: "off",
            class: [[{ filled: _ctx.computedModelValue || _ctx.searchQuery.length || open }, _ctx.borderClass, _ctx.inputClass], "custom-input text-base block w-full pt-6 pb-1 pl-5 pr-10 box-border outline-none rounded"]
          }, _ctx.$attrs, {
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchQuery = $event.target.value)),
            onFocus: _ctx.onFocus
          }), null, 16, ["display-value", "class", "onFocus"]),
          (_ctx.label)
            ? (_openBlock(), _createBlock(_component_ComboboxLabel, {
                key: 0,
                class: _normalizeClass([[_ctx.labelClass], "custom-input-label text-gray-400 absolute top-4 left-5 transition-all truncate"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.label), 1),
                  (_ctx.required && _ctx.showRequiredIndication && !_ctx.disabled)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          (_ctx.computedModelValue)
            ? (_openBlock(), _createElementBlock("button", {
                key: "clearButton",
                class: "absolute inset-y-0 right-0 flex items-center pr-5",
                onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)), ["stop"]))
              }, [
                _createVNode(_component_Icon, {
                  class: "h-3 w-3 text-gray-400",
                  src: _ctx.IconSource.Close,
                  "aria-hidden": "true"
                }, null, 8, ["src"])
              ]))
            : (_openBlock(), _createBlock(_component_ComboboxButton, {
                ref: "boxButton",
                key: "boxButton",
                class: "absolute inset-y-0 -right-1 flex items-center pr-5"
              }, {
                default: _withCtx(() => [
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_Spinner, {
                        key: 0,
                        class: "w-5 h-5",
                        active: true
                      }))
                    : (_openBlock(), _createBlock(_component_Icon, {
                        key: 1,
                        class: "h-5 w-5 text-gray-400",
                        src: _ctx.IconSource.Search,
                        "aria-hidden": "true"
                      }, null, 8, ["src"]))
                ]),
                _: 1
              }, 512))
        ]),
        _createElementVNode("span", {
          class: _normalizeClass(["min-h-4 flex text-xs text-left w-full ml-1", { 'text-red-600': _ctx.hint?.type === 'error', 'text-gray-500': _ctx.hint?.type === 'hint' }])
        }, _toDisplayString(_ctx.hint?.text || ''), 3),
        _createVNode(_Transition, {
          "enter-active-class": "transition duration-200 ease-out",
          "enter-from-class": "transform scale-95 opacity-0",
          "enter-to-class": "transform scale-100 opacity-100",
          "leave-active-class": "transition duration-100 ease-out",
          "leave-from-class": "transform scale-100 opacity-100",
          "leave-to-class": "transform scale-95 opacity-0"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ComboboxOptions, {
                class: "max-h-60 outline-none overflow-y-auto",
                static: ""
              }, {
                default: _withCtx(() => [
                  (_ctx.filteredItems.length === 0 && _ctx.searchQuery !== '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('global.uiElements.combobox.noResults')), 1))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                    return (_openBlock(), _createBlock(_component_ComboboxOption, {
                      key: item[_ctx.itemValue],
                      as: "template",
                      value: item
                    }, {
                      default: _withCtx(({ selected: selectedOption, active }) => [
                        _createElementVNode("li", {
                          class: _normalizeClass([[selectedOption ? 'bg-primary-300 bg-opacity-40' : 'hover:bg-gray-100', active ? 'bg-gray-100' : ''], "text-base p-3 text-left w-full cursor-pointer"])
                        }, [
                          _createElementVNode("span", {
                            class: _normalizeClass(["block truncate", { 'font-medium': selectedOption, 'font-normal': !selectedOption }])
                          }, [
                            _renderSlot(_ctx.$slots, "item", { item: item }, () => [
                              _createTextVNode(_toDisplayString(item[_ctx.itemText]), 1)
                            ], true)
                          ], 2)
                        ], 2)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 3
              })
            ], 512), [
              [_vShow, open]
            ])
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}
<template>
    <div class="tooltip relative inline-block">
        <div class="z-10" :class="[{ dotted: enableTooltip }]">{{ text }}</div>
        <div v-if="enableTooltip" class="tooltip-content z-20 invisible absolute bg-gray-800 text-white rounded py-2 px-3 divide-white divide-y top-0 right-0 overflow-auto max-h-42">
            <div v-for="searchTopic of directory.search_topics" :key="searchTopic.name">
                <h4 class="font-semibold text-lg whitespace-nowrap">{{ searchTopic.name }}</h4>
                <div class="text-sm">
                    <ul>
                        <li v-for="querable of searchTopic.querables" :key="querable">
                            {{ querable }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Directory } from '@/types';

export default defineComponent({
    props: {
        directory: {
            type: Object as () => Directory,
            default: [],
        },
    },
    computed: {
        text(): string {
            if (!this.enableTooltip) {
                return '-';
            }
            return this.$t('views.settings.directories.content.table.moreInfoTableRow');
        },
        enableTooltip(): boolean {
            return this.$props.directory.search_topics.flatMap((currentSearchTopic) => currentSearchTopic.querables).length > 0;
        },
    },
});
</script>

<style scoped>
.tooltip:hover .tooltip-content {
    visibility: visible !important;
}
.dotted {
    text-decoration-style: dotted;
    text-decoration-line: underline;
}
</style>

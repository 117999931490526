export enum IconSource {
    Pig = 1,
    Leave = 2,
    Moving = 3,
    Food = 4,
    DeathRate = 5,
    Status = 6,
    Economy = 7,
    CreateBatch = 8,
    CloseBatch = 9,
    Alarm = 10,
    Warning = 11,
    /* Status = 12, */
    /* Pig = 13, */
    Pigs = 14,
    DailyGrowth = 15,
    /* DeathRate = 16, */
    LanguageDK = 17,
    LanguageEN = 18,
    LanguageES = 19,
    EditEnterprise = 20,
    EditFarm = 21,
    Farm = 22,
    /* Food = 23, */
    Slaughter = 24,
    /* Economy = 25, */
    Electricity = 26,
    MalePig = 27,
    Medicince = 28,
    More = 29,
    Rent = 30,
    Stalk = 31,
    TransportCost = 32,
    Vaccine = 33,
    VetAndMedicine = 34,
    Vet = 35,
    UnknownFood = 36,
    FoodDelivery = 37,
    Settings = 38,
    Batch = 39,
    /* Electricity = 40, */
    /* MalePig = 41, */
    MalePigRegulation = 42,
    ButtonMedicine = 43,
    ButtonMore = 44,
    ButtonRent = 45,
    ButtonStalk = 46,
    ButtonStatus = 47,
    ButtonTransportCosts = 48,
    ButtonUserManagement = 49,
    ButtonVaccine = 50,
    ButtonVet = 51,
    ButtonVetAndMedicine = 52,
    Global = 53,
    Accepted = 54,
    ActionRequired = 55,
    Active = 56,
    Add = 57,
    AddAction = 58,
    /* Alarm = 59, */
    Check = 60,
    Close = 61,
    /* CloseBatch = 62, */
    /* CreateBatch = 63, */
    DatePicker = 64,
    /* DeathRate = 65, */
    Declined = 66,
    Delete = 67,
    DeleteRed = 68,
    DeleteWhite = 69,
    DownloadPDF = 70,
    DropdownDark = 71,
    DropdownLight = 72,
    DropdownPrimary = 73,
    /* Economy = 74, */
    Edit = 75,
    EditKPI = 76,
    Enterprise = 77,
    FarmFilter = 78,
    FarmFilterDark = 79,
    Farms = 80,
    FarmsPrimary = 81,
    Filter = 82,
    /* Food = 83, */
    FormSuccess = 84,
    Hamburger = 85,
    Inactive = 86,
    Language = 87,
    /* Leave = 88, */
    Location = 89,
    Login = 90,
    Logout = 91,
    MedicineActive = 92,
    MedicineInactive = 93,
    Minus = 94,
    /* Moving = 95, */
    NegativeTrend = 96,
    Notifications = 97,
    NotificationsPending = 98,
    /* Pig = 99, */
    PositiveTrend = 100,
    Print = 101,
    Read = 102,
    RefreshActivities = 103,
    RefreshActivitiesLight = 104,
    Right = 105,
    Search = 106,
    SelectFarms = 107,
    Selected = 108,
    SendMail = 109,
    Setup = 110,
    ShowPDF = 111,
    Sort = 112,
    SortDown = 113,
    SortUp = 114,
    /* Status = 115, */
    Switch = 116,
    TimePicker = 117,
    UnfoldControlData = 118,
    Unread = 119,
    UserManagement = 120,
    /* Warning = 121, */
    /* LanguageDK = 122, */
    /* LanguageEN = 123, */
    LogoDanishCrown = 124,
    NavActivityActive = 125,
    NavActivityInactive = 126,
    NavEconomyActive = 127,
    NavEconomyInactive = 128,
    NavFoodActive = 129,
    NavFoodInactive = 130,
    NavPigsActive = 131,
    NavPigsInactive = 132,
    NavProductionDataActive = 133,
    NavProductionDataInactive = 134,
    NavReportsActive = 135,
    NavReportsInactive = 136,
    NavSettings = 137,
    NavSlaughteredActive = 138,
    NavSlaughteredInactive = 139,
    NavSlaughtersActive = 140,
    NavSlaughtersInactive = 141,
    NavStatusActive = 142,
    NavStatusInactive = 143,
    NavMonitoringActive = 164,
    NavMonitoringInactive = 165,
    NavDataQualityActive = '/assets/icons/nav_data_quality_active.svg',
    NavDataQualityInactive = '/assets/icons/nav_data_quality_inactive.svg',
    LanguageSV = 144,
    AssignmentIcon = 145,
    TaskList = 151,
    Cookie = 149,
    Treatment = 146,
    SectionIcon = 150,
    BatchIcon = 148,
    WaterStatus = 162,
    CheckIn = 158,
    Weight = 163,
    Slaughtering = 161,
    OKIcon = 160,
    AlarmIcon = 156,
    AttentionIcon = 157,
    Notes = 159,
    NoteIcon = 155,
    DataQualityIcon = '/assets/icons/btn_data_quality.svg',
}

/* [
    "ic_pig.svg = 1",
    "ic_leave.svg = 2",
    "ic_moving.svg = 3",
    "ic_food.svg = 4",
    "ic_death_rate.svg = 5",
    "ic_status.svg = 6",
    "ic_economy.svg = 7",
    "ic_create_batch.svg = 8",
    "ic_close_batch.svg = 9",
    "ic_alarm.svg = 10",
    "ic_warning.svg = 11",
    "ic_status.svg = 12",
    "ic_pig.svg = 13",
    "ic_pigs.svg = 14",
    "ic_daily_growth.svg = 15",
    "ic_death_rate.svg = 16",
    "language_dk.svg = 17",
    "language_en.svg = 18",
    "language_es.svg = 19",
    "btn_edit_enterprise.svg = 20",
    "ic_edit_farm.svg = 21",
    "ic_farm.svg = 22",
    "ic_food.svg = 23",
    "ic_slaughter.svg = 24",
    "ic_economy.svg = 25",
    "btn_electricity.svg = 26",
    "btn_male_pig.svg = 27",
    "btn_medicine.svg = 28",
    "btn_more.svg = 29",
    "btn_rent.svg = 30",
    "btn_stalk.svg = 31",
    "btn_transport_cost.svg = 32",
    "btn_vaccine.svg = 33",
    "btn_vet_and_medicine.svg = 34",
    "btn_vet.svg = 35",
    "ic_unknown_food.svg = 36",
    "ic_food_delivery.svg = 37",
    "ic_settings.svg = 38",
    "btn_batch.svg = 39",
    "btn_electricity.svg = 40",
    "btn_male_pig.svg = 41",
    "btn_male_pig_regulation.svg = 42",
    "btn_medicine.svg = 43",
    "btn_more.svg = 44",
    "btn_rent.svg = 45",
    "btn_stalk.svg = 46",
    "btn_status.svg = 47",
    "btn_transport_cost.svg = 48",
    "btn_user_management.svg = 49",
    "btn_vaccine.svg = 50",
    "btn_vet.svg = 51",
    "btn_vet_and_medicine.svg = 52",
    "global.svg = 53",
    "ic_accepted.svg = 54",
    "ic_action_required.svg = 55",
    "ic_active.svg = 56",
    "ic_add.svg = 57",
    "ic_add_action.svg = 58",
    "ic_alarm.svg = 59",
    "ic_check.svg = 60",
    "ic_close.svg = 61",
    "ic_close_batch.svg = 62",
    "ic_create_batch.svg = 63",
    "ic_date_picker.svg = 64",
    "ic_death_rate.svg = 65",
    "ic_declined.svg = 66",
    "ic_delete.svg = 67",
    "ic_delete_red.svg = 68",
    "ic_delete_white.svg = 69",
    "ic_download_pdf.svg = 70",
    "ic_dropdown_dark.svg = 71",
    "ic_dropdown_light.svg = 72",
    "ic_dropdown_primary.svg = 73",
    "ic_economy.svg = 74",
    "ic_edit.svg = 75",
    "ic_edit_kpi.svg = 76",
    "ic_enterprise.svg = 77",
    "ic_farm_filter.svg = 78",
    "ic_farm_filter_dark.svg = 79",
    "ic_farms.svg = 80",
    "ic_farms_primary.svg = 81",
    "ic_filter.svg = 82",
    "ic_food.svg = 83",
    "ic_form_success.svg = 84",
    "ic_hamburger.svg = 85",
    "ic_inactive.svg = 86",
    "ic_language.svg = 87",
    "ic_leave.svg = 88",
    "ic_location.svg = 89",
    "ic_login.svg = 90",
    "ic_logout.svg = 91",
    "ic_medicine_active.svg = 92",
    "ic_medicine_inactive.svg = 93",
    "ic_minus.svg = 94",
    "ic_moving.svg = 95",
    "ic_negative_trend.svg = 96",
    "ic_notifications.svg = 97",
    "ic_notifications_pending.svg = 98",
    "ic_pig.svg = 99",
    "ic_positive_trend.svg = 100",
    "ic_print.svg = 101",
    "ic_read.svg = 102",
    "ic_refresh_activities.svg = 103",
    "ic_refresh_activities_light.svg = 104",
    "ic_right.svg = 105",
    "ic_search.svg = 106",
    "ic_select_farms.svg = 107",
    "ic_selected.svg = 108",
    "ic_send_mail.svg = 109",
    "ic_setup.svg = 110",
    "ic_show_pdf.svg = 111",
    "ic_sort.svg = 112",
    "ic_sort_down.svg = 113",
    "ic_sort_up.svg = 114",
    "ic_status.svg = 115",
    "ic_switch.svg = 116",
    "ic_time_picker.svg = 117",
    "ic_unfold_control_data.svg = 118",
    "ic_unread.svg = 119",
    "ic_user_management.svg = 120",
    "ic_warning.svg = 121",
    "language_dk.svg = 122",
    "language_en.svg = 123",
    "logo_danish_crown.svg = 124",
    "nav_activity_active.svg = 125",
    "nav_activity_inactive.svg = 126",
    "nav_economy_active.svg = 127",
    "nav_economy_inactive.svg = 128",
    "nav_food_active.svg = 129",
    "nav_food_inactive.svg = 130",
    "nav_pigs_active.svg = 131",
    "nav_pigs_inactive.svg = 132",
    "nav_production_data_active.svg = 133",
    "nav_production_data_inactive.svg = 134",
    "nav_reports_active.svg = 135",
    "nav_reports_inactive.svg = 136",
    "nav_settings.svg = 137",
    "nav_slaughtered_active.svg = 138",
    "nav_slaughtered_inactive.svg = 139",
    "nav_slaughters_active.svg = 140",
    "nav_slaughters_inactive.svg = 141",
    "nav_status_active.svg = 142",
    "nav_status_inactive.svg = 143",
    "language_sv.svg = 144",
    "tmp_assignment_icon.png = 145"
] */

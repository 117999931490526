import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 py-6 px-8 rounded-xl space-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "m-auto border-0",
    "mask-class": "grid",
    onClosing: _ctx.onModalClose
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          class: "mx-auto w-12 h-12",
          src: _ctx.notification.icon_url
        }, null, 8, ["src"]),
        _createElementVNode("p", null, _toDisplayString(_ctx.notification.message), 1)
      ])
    ]),
    _: 3
  }, 8, ["onClosing"]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9cc3f8f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "graph-grid grid gap-4 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericChartWithApiMultiselectOptions = _resolveComponent("GenericChartWithApiMultiselectOptions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.charts, (chart) => {
      return (_openBlock(), _createBlock(_component_GenericChartWithApiMultiselectOptions, {
        key: chart.chart,
        class: "h-80",
        chart: chart.chart,
        "options-callback": chart.optionsCallback,
        options: chart.options,
        "multiselect-options": chart.multiselectOptions,
        "add-parameters-callback": chart.addParametersCallback
      }, null, 8, ["chart", "options-callback", "options", "multiselect-options", "add-parameters-callback"]))
    }), 128))
  ]))
}
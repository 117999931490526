import { useQuery, type UseQueryOptions } from '@tanstack/vue-query';
import type { Ref } from 'vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

const DEFAULT_OPTIONS = {
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 5, // 5 minutes
};

export const queryKeys = {
    monitorOverviewCharts: () => ['charts', 'monitoring', 'monitorOverview'],
    useMonitorOverviewChartsEntry: ({ sectionId, hourly }: { sectionId: Ref<number>; hourly: Ref<boolean> }) => [...queryKeys.monitorOverviewCharts(), { sectionId, hourly }],
    monitorWaterConsumptionCharts: () => ['charts', 'monitoring', 'monitorWaterConsumption'],
    monitorWaterConsumptionChart: ({ sectionId, hourly }: { sectionId: Ref<number>; hourly: Ref<boolean> }) => [...queryKeys.monitorWaterConsumptionCharts(), { sectionId, hourly }],
    feedingSystemRuntimeCharts: () => ['charts', 'monitoring', 'feedingSystemRuntime'],
    feedingSystemRuntimeChart: ({ sectionId, hourly }: { sectionId: Ref<number>; hourly: Ref<boolean> }) => [...queryKeys.feedingSystemRuntimeCharts(), { sectionId, hourly }],
    weightCharts: () => ['charts', 'monitoring', 'weight'],
    weightChart: ({ sectionId, hourly }: { sectionId: Ref<number>; hourly: Ref<boolean> }) => [...queryKeys.weightCharts(), { sectionId, hourly }],
};

export function useMonitorOverviewCharts({ sectionId, hourly }: { sectionId: Ref<number>; hourly: Ref<boolean> }, options: UseQueryOptions<any> = {}) {
    return useQuery<any>({
        queryKey: queryKeys.useMonitorOverviewChartsEntry({ sectionId, hourly }),
        queryFn: () =>
            store.dispatch(ActionType.GetMonitorOverviewCharts, {
                form: {
                    location_id: sectionId.value,
                    hourly: hourly.value,
                },
            }),
        placeholderData: (previousData: any) => previousData,
        ...DEFAULT_OPTIONS,
        ...options,
    });
}

export function useMonitorWaterConsumptionChart({ sectionId, hourly }: { sectionId: Ref<number>; hourly: Ref<boolean> }, options: UseQueryOptions<any> = {}) {
    return useQuery<any>({
        queryKey: queryKeys.monitorWaterConsumptionChart({ sectionId, hourly }),
        queryFn: () =>
            store.dispatch(ActionType.GetMonitorChartData, {
                form: {
                    url: 'charts/monitor/water-consumption',
                    location_id: sectionId.value,
                    hourly: hourly.value,
                },
            }),
        ...DEFAULT_OPTIONS,
        ...options,
    });
}

export function useMonitorFeedingSystemRuntimeChart({ sectionId, hourly }: { sectionId: Ref<number>; hourly: Ref<boolean> }, options: UseQueryOptions<any> = {}) {
    return useQuery<any>({
        queryKey: queryKeys.feedingSystemRuntimeChart({ sectionId, hourly }),
        queryFn: () =>
            store.dispatch(ActionType.GetMonitorChartData, {
                form: {
                    url: 'charts/monitor/feeding-sys-runtime',
                    location_id: sectionId.value,
                    hourly: hourly.value,
                },
            }),
        ...DEFAULT_OPTIONS,
        ...options,
    });
}

export function useMonitorWeightChart({ sectionId, hourly }: { sectionId: Ref<number>; hourly: Ref<boolean> }, options: UseQueryOptions<any> = {}) {
    return useQuery<any>({
        queryKey: queryKeys.weightChart({ sectionId, hourly }),
        queryFn: () =>
            store.dispatch(ActionType.GetMonitorChartData, {
                form: {
                    url: 'charts/monitor/weight-measurements',
                    location_id: sectionId.value,
                    hourly: hourly.value,
                },
            }),
        ...DEFAULT_OPTIONS,
        ...options,
    });
}

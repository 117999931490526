import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label", "disabled"]
const _hoisted_2 = { class: "grid grid-cols-2 relative overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["bg-gray-200 rounded-md p-1 cursor-pointer", { 'opacity-50 cursor-default': _ctx.disabled }]),
    "aria-label": _ctx.computedModelValue ? _ctx.falseText : _ctx.trueText,
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.computedModelValue = !_ctx.computedModelValue))
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["absolute top-0 left-0 w-1/2 h-full rounded-md bg-primary-700 transition-transform duration-300", _ctx.computedModelValue ? '' : 'transform translate-x-full']),
        "aria-hidden": "true"
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["px-4 py-2 relative h-full border-0 transition-colors duration-300 focus:outline-none", { 'text-white': _ctx.computedModelValue }])
      }, _toDisplayString(_ctx.trueText), 3),
      _createElementVNode("div", {
        class: _normalizeClass(["px-4 py-2 relative h-full border-0 transition-colors duration-300 focus:outline-none", { 'text-white': !_ctx.computedModelValue }])
      }, _toDisplayString(_ctx.falseText), 3)
    ])
  ], 10, _hoisted_1))
}
<template>
    <button
        class="bg-gray-200 rounded-md p-1 cursor-pointer"
        :class="{ 'opacity-50 cursor-default': disabled }"
        :aria-label="computedModelValue ? falseText : trueText"
        :disabled="disabled"
        @click="computedModelValue = !computedModelValue"
    >
        <div class="grid grid-cols-2 relative overflow-hidden">
            <div class="absolute top-0 left-0 w-1/2 h-full rounded-md bg-primary-700 transition-transform duration-300" :class="computedModelValue ? '' : 'transform translate-x-full'" aria-hidden="true"></div>
            <div class="px-4 py-2 relative h-full border-0 transition-colors duration-300 focus:outline-none" :class="{ 'text-white': computedModelValue }">
                {{ trueText }}
            </div>
            <div class="px-4 py-2 relative h-full border-0 transition-colors duration-300 focus:outline-none" :class="{ 'text-white': !computedModelValue }">
                {{ falseText }}
            </div>
        </div>
    </button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
    props: {
        modelValue: {
            type: Boolean as () => boolean | number | string,
            required: true,
        },
        trueText: {
            type: String as () => string,
            required: true,
        },
        falseText: {
            type: String as () => string,
            required: true,
        },
        trueValue: {
            type: Boolean as () => boolean,
            default: true,
        },
        falseValue: {
            type: Boolean as () => boolean,
            default: false,
        },
        disabled: {
            type: Boolean as () => boolean,
            default: undefined,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const computedModelValue = computed({
            get() {
                return props.modelValue === props.trueValue;
            },
            set(newValue: boolean) {
                const value = newValue ? props.trueValue : props.falseValue;

                emit('update:modelValue', value);
            },
        });

        return {
            computedModelValue,
        };
    },
});
</script>

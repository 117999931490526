<template>
    <Listbox v-slot="{ open }" v-model="computedValue">
        <div class="relative">
            <ListboxButton class="overflow-hidden relative w-full shadow-none" :disabled="disabled" @focus="buttonFocused = true" @blur="buttonFocused = false">
                <CustomInput
                    ref="input-field"
                    v-model:value="inputDisplayValue"
                    :show-required-indication="showRequiredIndication"
                    :active="open"
                    :required="required"
                    :prepend="prepend"
                    :focus-highlight="buttonFocused"
                    :rules="[]"
                    input-type="text"
                    :label="label"
                    :details="hint"
                    :disabled="disabled"
                    :show-hint="showHint"
                    :error-state="!validInput"
                    tab-index="-1"
                    readonly
                    cursor="cursor-pointer"
                ></CustomInput>
                <span v-show="!disabled" class="absolute -mt-2 right-5 top-1/2"><ExpandIcon :expand="open" color="black"></ExpandIcon></span>
            </ListboxButton>
            <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-100 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <div v-show="open" class="absolute -mt-4 top-full left-0 w-full bg-white border z-30 rounded">
                    <ListboxOptions static :class="[dropdownHeightClass]" class="outline-none overflow-y-auto" @keydown.esc.stop>
                        <!-- If we support groups -->
                        <div v-if="groups">
                            <ListboxOption v-if="allowEmpty" v-slot="{ selected, active }" :value="returnObject ? null : ''">
                                <button
                                    type="button"
                                    :class="[selected ? 'bg-primary-300 bg-opacity-40' : 'hover:bg-gray-100', active ? 'bg-gray-100' : '']"
                                    class="p-3 h-12 inline w-full text-left cursor-pointer"
                                ></button>
                            </ListboxOption>
                            <div v-for="(itemGroup, itemGroupIndex) in filteredItems" :key="`item-group-${itemGroupIndex}`">
                                <ListboxOption
                                    v-if="
                                        groupSelectOptions.selectableCondition(itemGroup) ||
                                        (typeof groupSelectOptions.intermediateSelectableCondition === 'function' && groupSelectOptions.intermediateSelectableCondition(itemGroup))
                                    "
                                    :value="groupSelectOptions.value(itemGroup)"
                                >
                                    <button
                                        type="button"
                                        :class="[
                                            'p-3 h-full inline w-full text-left cursor-pointer hover:bg-gray-100',
                                            {
                                                'text-gray-400': typeof groupSelectOptions.intermediateSelectableCondition === 'function' && groupSelectOptions.intermediateSelectableCondition(itemGroup),
                                                'bg-primary-300 bg-opacity-40': selected,
                                                'bg-gray-100': active,
                                            },
                                        ]"
                                    >
                                        {{ itemGroup[itemText] }}
                                    </button>
                                </ListboxOption>
                                <button v-else type="button" class="cursor-default p-3 text-gray-400 h-full inline w-full text-left">{{ itemGroup[itemText] }}</button>
                                <div v-for="(item, itemIndex) in itemGroup[groupKey]" :key="`item-${itemGroupIndex}-${itemIndex}`">
                                    <ListboxOption
                                        v-if="
                                            subGroupSelectOptions.selectableCondition(item) ||
                                            (typeof subGroupSelectOptions.intermediateSelectableCondition === 'function' && subGroupSelectOptions.intermediateSelectableCondition(item))
                                        "
                                        v-slot="{ selected, active }"
                                        :value="subGroupSelectOptions.value(item)"
                                    >
                                        <button
                                            type="button"
                                            :class="[
                                                'py-3 pr-3 pl-7 h-full inline w-full text-left cursor-pointer hover:bg-gray-100',
                                                {
                                                    'text-gray-400': typeof subGroupSelectOptions.intermediateSelectableCondition === 'function' && subGroupSelectOptions.intermediateSelectableCondition(item),
                                                    'bg-primary-300 bg-opacity-40': selected,
                                                    'bg-gray-100': active,
                                                },
                                            ]"
                                        >
                                            {{ item[groupTextKey] }}
                                        </button>
                                    </ListboxOption>
                                    <button v-else type="button" class="cursor-default py-3 pr-3 pl-7 text-gray-400 h-full inline w-full text-left">{{ item[groupTextKey] }}</button>
                                    <div v-if="item[subGroupKey] && item[subGroupKey].length">
                                        <div v-for="(subItem, subItemIndex) in item[subGroupKey]" :key="`sub-item-${itemGroupIndex}-${itemIndex}-${subItemIndex}`">
                                            <ListboxOption v-slot="{ selected, active }" :value="returnObject ? subItem : subItem[subGroupValue]">
                                                <button
                                                    type="button"
                                                    class="py-3 pr-3 pl-12 h-full inline w-full text-left cursor-pointer hover:bg-gray-100"
                                                    :class="{ 'bg-primary-300 bg-opacity-40': selected, 'bg-gray-100': active }"
                                                >
                                                    {{ subItem[subGroupTextKey] }}
                                                </button>
                                            </ListboxOption>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- If we don't support groups -->
                        <div v-else>
                            <ListboxOption v-if="allowEmpty" v-slot="{ selected, active }" :value="returnObject ? null : ''">
                                <button
                                    type="button"
                                    :class="[selected ? 'bg-primary-300 bg-opacity-40' : 'hover:bg-gray-100', active ? 'bg-gray-100' : '']"
                                    class="p-3 h-12 inline text-left w-full cursor-pointer"
                                ></button>
                            </ListboxOption>
                            <ListboxOption v-for="item in filteredItems" :key="item[itemValue]" v-slot="{ selected, active }" :value="returnObject ? item : item[itemValue]">
                                <button
                                    type="button"
                                    :class="[selected ? 'bg-primary-300 bg-opacity-40' : 'hover:bg-gray-100', active ? 'bg-gray-100' : '']"
                                    class="p-3 h-full inline text-left w-full cursor-pointer"
                                >
                                    {{ item[itemText] }}
                                </button>
                            </ListboxOption>
                        </div>
                    </ListboxOptions>
                </div>
            </transition>
        </div>
    </Listbox>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import ExpandIcon from '@/components/icons/ExpandIcon.vue';
import { validatableField } from '@/mixins/validatableField';
import CustomInput from '@/components/ui/CustomInput.vue';

export default defineComponent({
    components: {
        Listbox,
        ListboxLabel,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        ExpandIcon,
        CustomInput,
    },
    mixins: [validatableField],
    emits: ['update:value', 'input'],
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            required: true,
        },
        items: {
            type: Array,
            default: [],
        },
        itemText: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        dropdownHeightClass: {
            type: String,
            default: 'max-h-64',
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        prepend: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        groups: {
            type: Boolean,
            default: false,
        },
        groupKey: {
            type: String,
            default: '',
        },
        groupTextKey: {
            type: String,
            default: '',
        },
        groupSelectOptions: {
            type: Object as () => { selectableCondition: (groupItem: any) => boolean; intermediateSelectableCondition: (groupItem: any) => boolean; selectValue: (groupItem: any) => any },
            default: { selectableCondition: () => false, intermediateSelectableCondition: () => false, selectValue: () => null },
        },
        subGroupKey: {
            type: String,
            default: '',
        },
        subGroupTextKey: {
            type: String,
            default: '',
        },
        subGroupValue: {
            type: String,
            default: '',
        },
        subGroupSelectOptions: {
            type: Object as () => { selectableCondition: (groupItem: any) => boolean; intermediateSelectableCondition: (groupItem: any) => boolean; selectValue: (groupItem: any) => any },
            default: { selectableCondition: () => false, intermediateSelectableCondition: () => false, selectValue: () => null },
        },
        showRequiredIndication: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as () => undefined | { type: 'error' | 'hint'; text: string },
            default: undefined,
        },
        showHint: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            buttonFocused: false,
        };
    },
    computed: {
        textColorClass(): string {
            return this.disabled ? 'text-gray-400' : 'text-dark-gray-600';
        },
        cursorClass(): string {
            return this.disabled ? 'cursor-default' : 'cursor-pointer';
        },
        validationClass(): string {
            if (!this.$props.required) {
                return '';
            }
            return this.validInput ? 'border-green-200' : 'border-red-300';
        },
        filteredItems() {
            return this.$props.items.filter((currentItem) => currentItem !== null);
        },
        computedValue: {
            get(): any {
                return this.value;
            },
            set(newValue: any) {
                this.$emit('update:value', newValue);
            },
        },
        inputDisplayValue(): string {
            if (!this.computedValue) {
                return '';
            }
            if (this.returnObject) {
                return this.computedValue[this.itemText];
            }
            const activeItem: any = this.items.find((currentItem: any) => currentItem[this.itemValue] === this.computedValue);
            return activeItem ? activeItem[this.itemText] : this.computedValue;
        },
    },
    watch: {
        computedValue() {
            this.$emit('input');
        },
    },
});
</script>

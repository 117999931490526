<template>
    <div class="px-8 py-6 space-y-8 h-full overflow-hidden">
        <div>
            <h5 class="font-semibold">
                {{ $t('views.monitoring.log.calendarModal.header') }}
            </h5>
        </div>
        <div class="pb-8 h-full overflow-auto">
            <ActionModal :initial-step-index="1" :initial-state="actionModalInitialState" :step-config="actionModalStepConfig" :header="actionModalHeader">
                <template #default="{ open }">
                    <div class="space-y-4 overflow-auto max-h-full">
                        <button
                            v-for="alarm of alarms"
                            :key="alarm.id"
                            type="button"
                            class="flex items-center space-x-4 bg-dark-gray-200 bg-opacity-80 py-2 px-4 w-full rounded-lg h-20"
                            @click="onAlarmClicked(alarm, open)"
                        >
                            <div class="flex relative h-full my-auto">
                                <Icon class="my-auto h-12 w-12" :src="alarm.icon"></Icon>
                            </div>
                            <div class="flex flex-shrink-0 flex-1 h-full my-auto">
                                <div class="my-auto">
                                    <div class="font-semibold text-left">{{ alarm.type }}</div>
                                    <div class="text-left text-sm">{{ alarm.cause }}</div>
                                    <div class="flex text-gray-400 text-sm">
                                        <span>{{ $d(alarm.date, 'long') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-shrink-0">
                                <Icon v-if="alarm.severity_level === 3" class="h-6 w-6" :src="IconSource.AlarmIcon"></Icon>
                                <Icon v-else-if="alarm.severity_level === 2" class="h-6 w-6" :src="IconSource.AttentionIcon"></Icon>
                                <Icon v-else class="h-6 w-6" :src="IconSource.OKIcon"></Icon>
                            </div>
                            <div class="flex flex-shrink-0 h-full my-auto">
                                <Icon class="my-auto h-3 w-3 mr-2" :src="IconSource.Right"></Icon>
                            </div>
                        </button>
                    </div>
                </template>
            </ActionModal>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Alarm, IconSource, MultiStepFormState, RecordForm } from '@/types';
import ActionModal from '@/components/common/actionModal/ActionModal.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';

export default defineComponent({
    components: { ActionModal },
    props: {
        alarms: {
            type: Array as () => (Alarm & { type: string; icon: number; cause: string })[],
            default: () => [],
        },
    },
    data() {
        return {
            actionModalStepConfig: [{}, { deleteButton: true, editable: true }, { disableRefetch: true, submitActionType: ActionType.PutAlarmForm }, {}],
            actionModalInitialState: undefined as undefined | MultiStepFormState,
            actionModalHeader: null as null | { icon_url: string; text: string },
            isLoadingActionForm: false,
            IconSource,
        };
    },
    methods: {
        async onAlarmClicked(alarm: Alarm, open: CallableFunction) {
            try {
                if (this.isLoadingActionForm) return;

                this.isLoadingActionForm = true;

                const alarmForm: RecordForm = await store.dispatch(ActionType.GetAlarmForm, { form: { alarm_id: alarm.id } });

                if (alarmForm) {
                    this.actionModalStepConfig[1] = { deleteButton: alarmForm.deletable, editable: alarmForm.editable };
                    this.actionModalInitialState = { alarm_id: alarm.id, location: null, form: alarmForm.form, confirmed: false, farm: null, event: null, record_id: null, notification: null };
                    this.actionModalHeader = { icon_url: IconSource.AlarmIcon as string, text: alarmForm.form.sections?.[0]?.name || '' };

                    open();
                }
            } finally {
                this.isLoadingActionForm = false;
            }
        },
    },
});
</script>

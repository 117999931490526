import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "bg-white relative rounded-lg grid py-6 px-10 m-auto transition-all transition-duration-2000 space-y-2 w-full outline-none",
  tabindex: "0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_MultiStepFormHeaderIcon = _resolveComponent("MultiStepFormHeaderIcon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    size: "full",
    "modal-class": `m-auto border-0 transition-width duration-500 ${_ctx.modalClass}`,
    "mask-class": "grid",
    onClosing: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closing')))
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx((scope) => [
      _createElementVNode("form", _hoisted_1, [
        (_ctx.headerIcon)
          ? (_openBlock(), _createBlock(_component_MultiStepFormHeaderIcon, {
              key: 0,
              "icon-source": _ctx.headerIcon,
              "is-final-step": _ctx.isFinalStep
            }, null, 8, ["icon-source", "is-final-step"]))
          : _createCommentVNode("", true),
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "component", _normalizeProps(_guardReactiveProps(scope)))
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 3
  }, 8, ["modal-class"]))
}
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white w-full relative rounded-lg grid py-6 px-10 m-auto space-y-4 max-w-128" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "font-bold text-primary-700" }
const _hoisted_5 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_MultiStepFormHeaderIcon = _resolveComponent("MultiStepFormHeaderIcon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": `m-auto border-0 max-w-6xl transition-width duration-500`,
    "mask-class": "grid"
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx((scope) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MultiStepFormHeaderIcon, {
          "icon-source": _ctx.IconSource.Food,
          "is-final-step": false
        }, null, 8, ["icon-source"]),
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.feed.mixes.deleteMixModal.header')), 1),
        _createElementVNode("p", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.feed.mixes.deleteMixModal.message')), 1),
          _createTextVNode(),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.mix.name) + "?", 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CustomButton, {
            disabled: _ctx.disableButtons,
            "color-preset": "secondary",
            onClick: scope.close
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.feed.mixes.deleteMixModal.cancelButtonLabel')), 1)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"]),
          _createVNode(_component_CustomButton, {
            loading: _ctx.disableButtons,
            "color-preset": "warning",
            onClick: ($event: any) => (_ctx.onDeleteMixClicked(scope.close))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('views.feed.mixes.deleteMixModal.confirmButtonLabel')), 1)
            ]),
            _: 2
          }, 1032, ["loading", "onClick"])
        ])
      ])
    ]),
    _: 3
  }))
}
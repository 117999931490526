<template>
    <DashboardContentLayout heading="" :has-period-picker="false" :has-pig-type-picker="false">
        <DataQualityBar v-if="hasDataQualityAssessmentModuleEnabled" />
        <div class="space-y-8">
            <KpiGrid></KpiGrid>
            <DashboardContentHeader :heading="$t('views.overview.header.heading')" :has-period-picker="true" :has-pig-type-picker="true"></DashboardContentHeader>
            <GraphGrid></GraphGrid>
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import KpiGrid from '@/views/dashboard/overview/KpiGrid.vue';
import GraphGrid from '@/views/dashboard/overview/GraphGrid.vue';
import DashboardContentHeader from '@/components/layouts/dashboardLayout/content/DashboardContentHeader.vue';
import DataQualityBar from './DataQualityBar.vue';
import { store } from '@/plugins/store';
import { Module } from '@/types';

export default defineComponent({
    components: { DashboardContentLayout, DashboardContentHeader, KpiGrid, GraphGrid, DataQualityBar },
    setup() {
        const hasDataQualityAssessmentModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.DataQualityAssessment));

        return { hasDataQualityAssessmentModuleEnabled };
    },
});
</script>

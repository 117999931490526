<template>
    <EmptyChartState :options="chartOptions" class="w-full grid">
        <GenericChart :options="chartOptions"></GenericChart>
    </EmptyChartState>
</template>

<script lang="ts">
import { defineComponent, computed, ComputedRef } from 'vue';
import { Options } from 'highcharts';
import GenericChart from '@/components/charts/GenericChart.vue';
import EmptyChartState from '@/components/charts/EmptyChartState.vue';
import useSplineChartOptions from '@/components/charts/composables/useSplineChartOptions';
import useLineChartOptions from '@/components/charts/composables/useLineChartOptions';
import useColumnChartOptions from '@/components/charts/composables/useColumnChartOptions';
import useStackedColumnChartOptions from '@/components/charts/composables/useStackedColumnChartOptions';
import useVariwideChartOptions from '@/components/charts/composables/useVariwideChartOptions';
import useChartAPI from '@/components/charts/composables/useChartAPI';
import { Chart, ChartType } from '@/types';
import { ActionType } from '@/plugins/store/actions';
import useStockChartOptions from './composables/useStockChartOptions';

export default defineComponent({
    components: { GenericChart, EmptyChartState },
    props: {
        chartName: {
            type: String as () => Chart,
            required: true,
        },
        chartType: {
            type: String as () => ChartType,
            default: '',
        },
        chartInstanceOptions: {
            type: Object,
            default: () => ({}),
        },
        seriesOptions: {
            type: Array as () => any[],
            default: () => [],
        },
        height: {
            type: Number,
            default: 300,
        },
        fetchActionType: {
            type: String as () => ActionType,
            default: ActionType.GetChart,
        },
    },
    setup(props) {
        const { options, series, title } = useChartAPI(props.chartName, props.fetchActionType);
        let chartOptionsClosure: (chartInstanceType: any) => ComputedRef<Options>;

        switch (props.chartType) {
            case ChartType.StackedColumnChart: {
                chartOptionsClosure = useStackedColumnChartOptions;
                break;
            }
            case ChartType.VariwideChart: {
                chartOptionsClosure = useVariwideChartOptions;
                break;
            }
            case ChartType.ColumnChart: {
                chartOptionsClosure = useColumnChartOptions;
                break;
            }
            case ChartType.LineChart: {
                chartOptionsClosure = useLineChartOptions;
                break;
            }
            case ChartType.Stock: {
                chartOptionsClosure = useStockChartOptions;
                break;
            }
            default: {
                chartOptionsClosure = useSplineChartOptions;
            }
        }

        const chartOptions = computed(() => {
            if (options.value) {
                return computed(() => options.value);
            }

            if (series.value.length === 0) {
                return null;
            }

            if (props.seriesOptions.length && props.seriesOptions.length <= series.value.length) {
                props.seriesOptions.forEach((currentSeriesOptions, index) => {
                    Object.assign(series.value[index], currentSeriesOptions);
                });
            }

            return chartOptionsClosure({
                title: { text: title.value },
                series: series.value,
                chart: { height: props.height },
                ...props.chartInstanceOptions,
            });
        });

        return { chartOptions };
    },
});
</script>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-20 bg-white rounded-2xl py-4 px-4 grid shadow-md w-full overflow-hidden" }
const _hoisted_2 = { class: "flex space-x-4 w-full" }
const _hoisted_3 = { class: "my-auto min-w-14" }
const _hoisted_4 = { class: "my-auto overflow-hidden w-full" }
const _hoisted_5 = { class: "flex space-x-6 h-8" }
const _hoisted_6 = { class: "text-xl font-semibold tracking-tighter truncate pr-1" }
const _hoisted_7 = { class: "max-w-full" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.kpiType?.icon_url)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              class: "w-14 h-14",
              src: _ctx.kpiType?.icon_url
            }, null, 8, ["src"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.kpiValue), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", {
            class: "text-gray-600 text-lg max-w-full",
            innerHTML: _ctx.kpiText
          }, null, 8, _hoisted_8)
        ])
      ])
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "text-xl font-semibold text-center" }
const _hoisted_3 = { class: "flex flex-col h-full min-h-44" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.header?.text || _ctx.state.event?.name || ''), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomSelect, {
        value: _ctx.selectedLocation,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLocation) = $event)),
        class: "self-start w-full",
        items: _ctx.farms,
        groups: "",
        "group-key": "locations",
        "group-text-key": "name",
        "group-select-options": ({
                    selectableCondition: (group) => _ctx.siteWorksForAction(group),
                    intermediateSelectableCondition: (group) => group.locations && group.locations.length === 1 && _ctx.siteWorksForAction(group.locations[0]),
                    value: (group) => (_ctx.siteWorksForAction(group) ? group : group.locations[0]),
                } as any),
        "sub-group-key": "locations",
        "sub-group-text-key": "name",
        "sub-group-select-options": ({
                    selectableCondition: (group) => _ctx.siteWorksForAction(group),
                    intermediateSelectableCondition: (group) => group.locations && group.locations.length === 1 && _ctx.siteWorksForAction(group.locations[0]),
                    value: (group) => (_ctx.siteWorksForAction(group) ? group : group.locations[0]),
                } as any),
        "return-object": "",
        required: "",
        "show-required-indication": "",
        "item-value": "name",
        "allow-empty": false,
        "dropdown-height-class": "max-h-84",
        "item-text": "name",
        rules: [{ validate: (value) => value !== null, message: '' }],
        label: _ctx.$t('global.forms.steps.location.selectLocationFieldLabel'),
        disabled: false,
        "show-hint": false
      }, null, 8, ["value", "items", "group-select-options", "sub-group-select-options", "rules", "label"]),
      _createVNode(_component_ExpandCollapseTransition, null, {
        default: _withCtx(() => [
          (_ctx.isMissingSelectedSection)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_CustomSelect, {
                  value: _ctx.selectedSection,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSection) = $event)),
                  class: "self-start w-full",
                  items: _ctx.activeLocationSections,
                  "return-object": "",
                  required: "",
                  "show-required-indication": "",
                  "item-value": "id",
                  "allow-empty": false,
                  "dropdown-height-class": "max-h-84",
                  "item-text": "name",
                  rules: [{ validate: (value) => value !== null, message: '' }],
                  label: _ctx.$t('global.forms.steps.location.selectSectionFieldLabel'),
                  "show-hint": false
                }, null, 8, ["value", "items", "rules", "label"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-8" }
const _hoisted_2 = { class: "text-2xl font-semibold text-center" }
const _hoisted_3 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('views.settings.directories.content.modals.manageDirectory.header')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        onClick: _ctx.onSubmitClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.directories.content.modals.manageDirectory.switchButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_CustomButton, {
        disabled: _ctx.disableButtons,
        onClick: _ctx.onSkipClicked
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('views.settings.directories.content.modals.manageDirectory.editButtonLabel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}
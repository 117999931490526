<template>
    <div class="space-y-6">
        <div v-for="item in items" :key="item.farm_id" class="border border-gray-300 rounded-lg px-4 py-5">
            <div class="flex items-center space-x-3">
                <div>
                    <Icon class="h-6" :src="IconSource.FarmsPrimary"></Icon>
                </div>
                <div>
                    <div class="font-bold text-lg">{{ item.farm_name }}</div>
                    <div class="text-sm text-gray-500">
                        {{ item.barns.length }} {{ item.barns.length === 1 ? $t('views.monitoring.main.headings.barn') : $t('views.monitoring.main.headings.barnPlural') }}, {{ item.sectionCount }}
                        {{ item.sectionCount === 1 ? $t('views.monitoring.main.headings.section') : $t('views.monitoring.main.headings.sectionPlural') }}
                    </div>
                </div>
            </div>
            <div class="mt-5 space-y-5">
                <FoodBarnSection v-for="barn in item.barns" :key="barn.headers.barn_id" :barn="barn" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '@/components/icons/Icon.vue';
import { MonitoringDataItem } from '../MonitoringTabs.vue';
import { IconSource } from '@/types';
import FoodBarnSection from './FoodBarnSection.vue';

export default defineComponent({
    components: { Icon, FoodBarnSection },
    props: {
        items: {
            type: Array as () => MonitoringDataItem[],
            required: true,
        },
    },
    setup() {
        return {
            IconSource,
        };
    },
});
</script>

import type { VueQueryPluginOptions } from '@tanstack/vue-query';
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5, // 5 minutes
            gcTime: 1000 * 60 * 10, // 10 minutes
            refetchOnMount: true,
            refetchOnWindowFocus: true,
        },
    },
});

const options: VueQueryPluginOptions = {
    queryClient,
};

export { VueQueryPlugin, options };

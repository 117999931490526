<template>
    <div class="graph-grid grid gap-4 w-full">
        <GenericChartWithApiMultiselectOptions
            v-for="chart of charts"
            :key="chart.chart"
            class="h-80"
            :chart="chart.chart"
            :options-callback="chart.optionsCallback"
            :options="chart.options"
            :multiselect-options="chart.multiselectOptions"
            :add-parameters-callback="chart.addParametersCallback"
        ></GenericChartWithApiMultiselectOptions>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Chart, ChartMultiSelectOptions } from '@/types';
import useSplineChartOptions from '@/components/charts/composables/useSplineChartOptions';
import GenericChartWithApiMultiselectOptions from '@/components/charts/GenericChartWithApiMultiselectOptions.vue';

export default defineComponent({
    components: { GenericChartWithApiMultiselectOptions },
    computed: {
        charts() {
            return [
                { chart: Chart.OverviewPigsTally, optionsCallback: useSplineChartOptions, options: null },
                {
                    chart: Chart.OverviewDailyGain,
                    optionsCallback: useSplineChartOptions,
                    options: null,
                    multiselectOptions: {
                        enabled: true,
                        items: [
                            { text: this.$t('views.overview.content.tables.dailyGain.multiselect.items.dailyGain'), value: 'values', defaultSelected: true },
                            { text: this.$t('views.overview.content.tables.dailyGain.multiselect.items.refDailyGain'), value: 'references', defaultSelected: false },
                        ],
                    },
                    addParametersCallback: (multiselectOptions: ChartMultiSelectOptions, selectedMultiselectOptions: { text: string; value: string }[]) =>
                        multiselectOptions.items.reduce((accum, current) => `${accum}&${current.value}=${selectedMultiselectOptions.some((currentItem) => currentItem.value === current.value)}`, ''),
                },
                {
                    chart: Chart.OverviewFeedConversion,
                    optionsCallback: useSplineChartOptions,
                    options: null,
                    multiselectOptions: {
                        enabled: true,
                        items: [
                            { text: this.$t('views.overview.content.tables.feedConversion.multiselect.items.fourWeeksAverage'), value: '4', defaultSelected: false },
                            { text: this.$t('views.overview.content.tables.feedConversion.multiselect.items.eightWeeksAverage'), value: '8', defaultSelected: false },
                            { text: this.$t('views.overview.content.tables.feedConversion.multiselect.items.thirteenWeeksAverage'), value: '13', defaultSelected: true },
                            { text: this.$t('views.overview.content.tables.feedConversion.multiselect.items.refThirteenWeeksAverage'), value: 'REF_13_WEEKS_AVG', defaultSelected: false },
                        ],
                    } as ChartMultiSelectOptions,
                    addParametersCallback: (multiselectOptions: ChartMultiSelectOptions, selectedMultiselectOptions: { text: string; value: string }[]) => {
                        let result = '&weeks=';
                        result += multiselectOptions.items
                            .filter((currentItem) => currentItem.value !== 'REF_13_WEEKS_AVG' && selectedMultiselectOptions.some((currentSelectedItem) => currentSelectedItem.value === currentItem.value))
                            .map((current) => current.value)
                            .join(',');
                        result += `&ref=${selectedMultiselectOptions.some((currentSelectedItem) => currentSelectedItem.value === 'REF_13_WEEKS_AVG')}`;
                        return result;
                    },
                },
                {
                    chart: Chart.OverviewDeaths,
                    optionsCallback: useSplineChartOptions,
                    options: null,
                },
            ];
        },
    },
});
</script>

<style scoped>
@media (min-width: 1120px) {
    .graph-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>

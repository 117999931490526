import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col divide-y" }
const _hoisted_2 = { class: "relative flex items-start" }
const _hoisted_3 = { class: "flex items-center space-x-2.5" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "flex items-center flex-wrap leading-tight" }
const _hoisted_6 = { class: "mr-1" }
const _hoisted_7 = {
  key: 0,
  class: "text-sm text-gray-500"
}
const _hoisted_8 = { class: "text-sm opacity-50" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_NestedLocation = _resolveComponent("NestedLocation", true)!
  const _component_ExpandCollapseTransition = _resolveComponent("ExpandCollapseTransition")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.option.children)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "absolute top-5 hover:bg-gray-100 p-2.5 rounded-full",
            style: _normalizeStyle(`left: ${_ctx.leftOffset.icon + _ctx.leftOffset.nesting}rem`),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.expand = !_ctx.expand), ["prevent"]))
          }, [
            _createVNode(_component_Icon, {
              src: _ctx.IconSource.DropdownDark,
              class: _normalizeClass([{ 'rotate-180': _ctx.expand }, "h-4 w-4 transition-transform transform"])
            }, null, 8, ["src", "class"])
          ], 4))
        : _createCommentVNode("", true),
      _createElementVNode("label", {
        class: "flex items-center justify-between flex-grow cursor-pointer py-4 pr-4 hover:bg-gray-100 space-x-4",
        style: _normalizeStyle(`padding-left: ${_ctx.leftOffset.nesting + _ctx.leftOffset.text}rem`)
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Icon, {
            src: _ctx.iconSource,
            class: "h-7 w-7"
          }, null, 8, ["src"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.option.title), 1),
              (_ctx.option.chr && _ctx.option.chr.length)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "(" + _toDisplayString(_ctx.option.chr.join(',')) + ")", 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.option.subtitle), 1)
          ])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_CustomCheckbox, {
            checked: _ctx.option.selected,
            "onUpdate:checked": _ctx.onToggle
          }, null, 8, ["checked", "onUpdate:checked"])
        ])
      ], 4)
    ]),
    (_ctx.option.children)
      ? (_openBlock(), _createBlock(_component_ExpandCollapseTransition, { key: 0 }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("ul", {
              class: _normalizeClass({ 'divide-y': _ctx.level !== 1 })
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.option.children, (currentChildOption) => {
                return (_openBlock(), _createBlock(_component_NestedLocation, {
                  key: currentChildOption.id,
                  option: currentChildOption,
                  path: `${_ctx.path}.${currentChildOption.id}`,
                  "location-picker-state": _ctx.locationPickerState,
                  level: _ctx.level + 1,
                  nested: _ctx.isNested,
                  onLocationToggled: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('location-toggled', $event)))
                }, null, 8, ["option", "path", "location-picker-state", "level", "nested"]))
              }), 128))
            ], 2), [
              [_vShow, _ctx.expand]
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericChartWithApi = _resolveComponent("GenericChartWithApi")!
  const _component_MainViewCard = _resolveComponent("MainViewCard")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    class: "space-y-8",
    heading: _ctx.$t('views.debug.header.heading'),
    "has-period-picker": true,
    "has-pig-type-picker": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MainViewCard, { class: "pt-4 pb-2 px-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_GenericChartWithApi, {
              "fetch-action-type": _ctx.chart.fetchActionType,
              "chart-name": _ctx.chart.name,
              height: _ctx.chart.height,
              "chart-type": _ctx.chart.type
            }, null, 8, ["fetch-action-type", "chart-name", "height", "chart-type"])
          ]),
          _: 1
        }),
        (_ctx.playgroundModalInitialState.form)
          ? (_openBlock(), _createBlock(_component_ActionModal, {
              key: 0,
              header: { icon_url: null, text: 'Playground' },
              "initial-step-index": 1,
              "initial-state": _ctx.playgroundModalInitialState
            }, {
              default: _withCtx(({ open }) => [
                _createVNode(_component_CustomButton, {
                  class: "w-40",
                  onClick: open
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Playground form ")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["initial-state"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["heading"]))
}
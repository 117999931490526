import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "flex items-center space-x-3" }
const _hoisted_3 = { class: "font-bold text-lg" }
const _hoisted_4 = { class: "text-sm text-gray-500" }
const _hoisted_5 = { class: "mt-5 space-y-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FoodBarnSection = _resolveComponent("FoodBarnSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.farm_id,
        class: "border border-gray-300 rounded-lg px-4 py-5"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_Icon, {
              class: "h-6",
              src: _ctx.IconSource.FarmsPrimary
            }, null, 8, ["src"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(item.farm_name), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.barns.length) + " " + _toDisplayString(item.barns.length === 1 ? _ctx.$t('views.monitoring.main.headings.barn') : _ctx.$t('views.monitoring.main.headings.barnPlural')) + ", " + _toDisplayString(item.sectionCount) + " " + _toDisplayString(item.sectionCount === 1 ? _ctx.$t('views.monitoring.main.headings.section') : _ctx.$t('views.monitoring.main.headings.sectionPlural')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.barns, (barn) => {
            return (_openBlock(), _createBlock(_component_FoodBarnSection, {
              key: barn.headers.barn_id,
              barn: barn
            }, null, 8, ["barn"]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}
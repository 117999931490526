import axios, { AxiosResponse, AxiosRequestConfig, Method } from 'axios';
import { createToastInterface, ToastInterface } from 'vue-toastification';
import { Form } from '@makeabledk/vue-ui/support/http';
import ToastOptions from '@/plugins/toastification';
import { i18n } from '@/plugins/internationalization/i18n';
import Authentication from '@/plugins/authentication';
import { LoggingLevel, RequestOptions } from '@/types';

class ApiClient {
    private static instance: ApiClient | null = null;

    private static BASE_URL = `${process.env.VUE_APP_API_SERVER_URL}`;

    private toast = null as ToastInterface | null;

    // @ts-ignore
    private config: { headers: { [key: string]: string | undefined } };

    /* Most endpoints require both userProfileLanguage and authToken to be explicitly set in request headers.
    The api state determines whether they're ready */
    public apiState = { userProfileLanguage: null as string | null, authToken: null as string | null };

    constructor() {
        if (!ApiClient.instance) {
            this.toast = createToastInterface(ToastOptions);
            this.config = {
                headers: {
                    'X-Platform': 'dashboard',
                    'Cache-Control': 'no-cache',
                    'X-Language': 'da', // Required fallback-language for endpoints that don't actually require a language. All other endpoints have to wait for this to be overridden
                    'Ocp-Apim-Subscription-Key': process.env.VUE_APP_DUMMY_API_KEY,
                    'X-Version': `${process.env.VUE_APP_API_VERSION}`,
                },
            };
            ApiClient.instance = this;
        }

        return ApiClient.instance;
    }

    setAuthToken(authToken: string) {
        this.apiState.authToken = authToken;
    }

    setLanguage(language: string) {
        this.apiState.userProfileLanguage = language;
        this.config.headers['X-Language'] = language;
    }

    async send(method: Method, url: string, options: RequestOptions, payload?: object) {
        await this.waitForPreconditions(options);
        const form = Form.wrap(payload);
        form.startProcessing();
        const data = form.data();
        const authToken = Authentication.getToken();

        ApiClient.log('Calling endpoint = ', `${ApiClient.BASE_URL}/${url}`);
        if (['put', 'patch', 'post'].includes(method)) {
            ApiClient.log('With data = ', JSON.stringify(data));
        }

        try {
            const requestObject = { method, url: `${ApiClient.BASE_URL}/${url}`, data, headers: this.config.headers };
            if (options.responseType) {
                (requestObject as any).responseType = options.responseType;
            }
            if (authToken) {
                (requestObject as any).headers.Authorization = `Bearer ${authToken}`;
            }

            const response = await axios.request(requestObject);
            ApiClient.log(`Response from = ${url}`, response);
            form.finishProcessing();
            return response.data;
            // eslint-disable-next-line
        } catch (error: any) {
            ApiClient.log('Error response: ', error.response);
            if (error.response.data.messages) {
                form.setErrors(error.response.data.messages);
            } else {
                form.setErrors([{ code: error.response.status, message: 'Sorry, an unknown error occurred!' }]);
            }
            return this.handleError(error.response, options, form, error.response.config, url);
        }
    }

    private async waitForPreconditions(options: RequestOptions) {
        if (!options.skipAuthCheck) {
            while (!this.apiState.authToken) await new Promise((resolve) => setTimeout(resolve, 100));
        }
        if (!options.skipLanguageCheck) {
            while (!this.apiState.userProfileLanguage) await new Promise((resolve) => setTimeout(resolve, 100));
        }
    }

    private handleError(error: AxiosResponse, options: RequestOptions, form: Form<any>, config: AxiosRequestConfig, url: string) {
        if (error.status === 401 && config.url !== `${ApiClient.BASE_URL}/auth/token/refresh`) {
            return this.retryAfterAuth(config, options, form, url);
        }
        if (error.status === 403) {
            this.toast?.error(i18n.global.t('global.errors.unauthorized'));
        } else if ([400, 404, 500, 503].includes(error.status) && !options.skipHandleError) {
            this.toast?.error(i18n.global.t('global.errors.unknown'));
        } else {
            throw error;
        }
    }

    private async retryAfterAuth(config: AxiosRequestConfig, options: RequestOptions, form: Form<any>, url: string): Promise<any> {
        ApiClient.log('Retrying request after reauth', url);
        await Authentication.refreshToken(false);
        ApiClient.log('Refresh token after 401 was successful');

        if (!options.preventRetryIfFail) {
            return this.send(config.method as Method, url, { ...options, preventRetryIfFail: true }, form);
        }
        return null;
    }

    /* For debugging purposes - Logs data coming in and out of the APIClient only when using hotReload */
    private static log(message?: any, ...optionalParams: any[]) {
        if (process.env.VUE_APP_LOGGING_LEVEL === LoggingLevel.Debug) {
            console.log(message, ...optionalParams);
        }
    }
}

const instance = new ApiClient();
Object.freeze(instance);

export default instance;

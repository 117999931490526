<template>
    <MainViewCard class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.settings.directories.content.table.title') }}</h4>
            <span class="text-right mr-6 w-80">
                <SearchInput v-model:value="search" :label="$t('views.settings.directories.content.table.searchFieldLabel')"></SearchInput>
            </span>
            <SettingsFormModal
                v-if="createDirectorySettingEvent && canCreateDirectores"
                :initial-state="{
                    event: createDirectorySettingEvent,
                    form: null,
                    confirmed: false,
                }"
                @closing="onFormModalClosing"
            >
                <template #default="{ open }">
                    <AddButton text-class="font-medium" @click="open">
                        {{ $t('views.settings.directories.content.createDirectoryButtonLabel') }}
                    </AddButton>
                </template>
            </SettingsFormModal>
        </div>
        <CustomTable expandable :items="filteredItems" :headers="headers">
            <template #header_actions>
                <th class="w-8"></th>
            </template>

            <template #active="{ item, getHeaderClass, getWidth, header }">
                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <div class="flex rounded-full h-5 w-5 bg-opacity-20" :class="item.active ? 'bg-green-200' : 'bg-red-400'">
                        <Icon class="m-auto" :src="item.active ? IconSource.Active : IconSource.Inactive"></Icon>
                    </div>
                </td>
            </template>

            <template #search_topics="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <DirectoriesMoreInfoTooltip :directory="item"></DirectoriesMoreInfoTooltip>
                </td>
            </template>

            <template #actions="{ item }">
                <td class="w-8 my-auto">
                    <span v-if="canManageDirectory || !item.active" class="flex justify-end">
                        <SettingsFormModal
                            :initial-state="editDirectoryInitialState"
                            :initial-step-index="initialEditDirectoryModalIndex"
                            :step-config="editDirectoryStepConfig"
                            :add-steps="addStepsToEditDirectory"
                            @closing="onFormModalClosing"
                        >
                            <template #default="{ open }">
                                <button type="button" class="p-2 -mr-2" @click="onEditDirectoryClicked(item, open)">
                                    <Icon class="h-3 w-3" :src="IconSource.Right"></Icon>
                                </button>
                            </template>
                        </SettingsFormModal>
                    </span>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, shallowRef, ref, computed, watch } from 'vue';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Directory, SettingEvent, SettingEventType, TableHeader, UserRole } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import SettingsFormModal from '@/components/common/settingsFormModal/SettingsFormModal.vue';
import AddButton from '@/components/ui/AddButton.vue';
import ManageDirectoryForm from '@/views/dashboard/settings/directories/ManageDirectoryForm.vue';
import SwitchDirectoryForm from '@/views/dashboard/settings/directories/SwitchDirectoryForm.vue';
import DirectoriesMoreInfoTooltip from '@/views/dashboard/settings/directories/DirectoriesMoreInfoTooltip.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import useFilterableTable from '@/composables/useFilterableTable';

export default defineComponent({
    components: { MainViewCard, CustomTable, SearchInput, SettingsFormModal, AddButton, DirectoriesMoreInfoTooltip },
    setup() {
        store.dispatch(ActionType.GetDirectories);
        store.dispatch(ActionType.GetActions);
        store.dispatch(ActionType.GetSettingEvents);

        const addStepsToEditDirectory = ref([{ component: shallowRef(ManageDirectoryForm) }, { component: shallowRef(SwitchDirectoryForm) }]);
        const editingDirectory = ref<null | Directory>(null);
        const editDirectoryInitialState = ref<{
            event: null | SettingEvent;
            form: null;
            confirmed: boolean;
        }>({
            event: null,
            form: null,
            confirmed: false,
        });

        const headers = computed<TableHeader[]>(() => [
            {
                text: i18n.global.t('views.settings.directories.content.table.headers.active'),
                value: 'active',
                align: 'left',
                sortable: true,
                searchable: false,
            },
            {
                text: i18n.global.t('views.settings.directories.content.table.headers.name'),
                value: 'name',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.settings.directories.content.table.headers.mainNumber'),
                value: 'main_number',
                align: 'left',
                sortable: true,
                searchable: true,
            },
            {
                text: i18n.global.t('views.settings.directories.content.table.headers.moreInfo'),
                value: 'search_topics',
                align: 'left',
                sortable: false,
                searchable: true,
                searchComparison: (item: Directory, search) => {
                    const searchableSearchTopics = item.search_topics.filter((currentSearchTopic) => currentSearchTopic.searchable);
                    const querables = searchableSearchTopics.flatMap((currentSearchTopic) => currentSearchTopic.querables);
                    return querables.join(',').toLowerCase().trim().includes(search.toLowerCase().trim());
                },
            },
            {
                text: '',
                value: 'actions',
                align: 'right',
                sortable: false,
                searchable: false,
            },
        ]);
        const items = computed(() => store.state.directories);
        const { search, filteredItems } = useFilterableTable(items, headers);

        /**
         * User clicks on the active directory: Active directory info opens
         * User without manage privileges clicks on the active directory: Nothing happens (button is hidden)
         * User without manage privileges clicks on another directory: Switch directory form opens
         * User with manage privileges clicks on another directory: User is asked whether he wants to see directory info or switch directory
         */
        const initialEditDirectoryModalIndex = computed(() => {
            if (editingDirectory.value?.active) {
                return 2;
            }
            return canManageDirectory.value ? 0 : 1;
        });
        const canCreateDirectores = computed(() => store.state.userRole === UserRole.solutionAdmin);
        const canManageDirectory = computed(() => store.getters.loggedInUserCanManageDirectory);

        const editDirectoryStepConfig = computed(() => [
            {},
            { directory: editingDirectory.value },
            { directory: editingDirectory.value },
            {},
            {
                rejectable: false,
                submitActionType: ActionType.PutForm,
                editButtonLabel: i18n.global.t('global.forms.steps.confirmation.backButtonLabel'),
            },
            {},
        ]);
        const createDirectorySettingEvent = computed(() => store.state.settingEvents.find((currentSettingEvent) => currentSettingEvent.type === SettingEventType.directoryCreate));
        const editDirectorySettingEvent = computed(() => store.state.settingEvents.find((currentSettingEvent) => currentSettingEvent.type === SettingEventType.directoryData));

        function onFormModalClosing(isFinalStep: boolean) {
            if (isFinalStep) {
                store.dispatch(ActionType.GetDirectories, { options: { ignoreCache: true } });
            }
        }

        function onEditDirectoryClicked(directory: Directory, open: CallableFunction) {
            editingDirectory.value = directory;
            open();
        }

        watch(
            editDirectorySettingEvent,
            (newValue: SettingEvent | undefined) => {
                if (newValue) {
                    editDirectoryInitialState.value.event = newValue;
                }
            },
            { immediate: true, deep: true }
        );

        return {
            addStepsToEditDirectory,
            editingDirectory,
            editDirectoryInitialState,
            onFormModalClosing,
            onEditDirectoryClicked,
            search,
            filteredItems,
            headers,
            createDirectorySettingEvent,
            editDirectorySettingEvent,
            editDirectoryStepConfig,
            canCreateDirectores,
            canManageDirectory,
            initialEditDirectoryModalIndex,
        };
    },
});
</script>

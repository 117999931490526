<template>
    <div class="space-y-6 h-full min-h-60">
        <h2 class="text-xl font-semibold text-center">{{ $t('global.forms.steps.confirmation.header') }}</h2>
        <div>
            <div v-if="computedState.form" class="grid gap-y-4 h-full">
                <div v-for="(section, sectionIndex) of sections" :key="section.id" class="grid grid-cols-2 py-4 px-6" :class="{ 'bg-gray-100 rounded-lg': sectionIndex % 2 === 0 }">
                    <h3 v-if="sectionIndex > 0" class="text-lg font-semibold mb-2 text-center col-span-2">{{ section.name }}</h3>
                    <ConfirmationElement
                        v-for="element of section.children"
                        :key="element.id"
                        :class="{ 'col-span-2': doubleWidthFieldIds.includes(element.id) }"
                        class="p-2"
                        :label="`${element.label || ''}${element.required && element.label ? '*' : ''}`"
                    >
                        {{ formatValue(element) }}
                    </ConfirmationElement>
                </div>
                <div v-if="computedState.notification?.rejected || computedState.notification?.approved">
                    <h3 class="text-center text-primary-800 text-xl font-semibold">
                        {{ computedState.notification?.approved ? $t('global.forms.steps.confirmation.alreadyApprovedMessage') : $t('global.forms.steps.confirmation.alreadyRejectedMessage') }}
                    </h3>
                </div>
                <div v-if="config.rejectable && computedState.notification?.disabled !== true" class="grid grid-cols-10 gap-4">
                    <div :class="!computedState.notification?.rejected ? 'col-span-10' : 'col-span-6 order-2'">
                        <CustomButton :disabled="disableButtons" :disable="!formValid" @click="onSubmit">
                            {{ $t('global.forms.steps.confirmation.sendButtonLabel') }}
                        </CustomButton>
                    </div>
                    <div class="col-span-4">
                        <CustomButton :disabled="disableButtons" color-preset="secondary" @click="onBackClicked">
                            {{ $t('global.forms.steps.confirmation.editButtonLabel') }}
                        </CustomButton>
                    </div>
                    <div v-if="!computedState.notification?.rejected" class="col-span-6">
                        <CustomButton :disabled="disableButtons" color-preset="warning" @click="onRejectClicked">
                            {{ $t('global.forms.steps.confirmation.rejectButtonLabel') }}
                        </CustomButton>
                    </div>
                </div>
                <div v-else-if="computedState.notification?.disabled !== true" class="grid grid-cols-10 gap-x-4">
                    <div class="col-span-4">
                        <CustomButton :disabled="disableButtons" color-preset="secondary" @click="onBackClicked">
                            {{ config.editButtonLabel || $t('global.forms.steps.confirmation.editButtonLabel') }}
                        </CustomButton>
                    </div>
                    <div class="col-span-6">
                        <CustomButton :loading="disableButtons" :disabled="!formValid" @click="onSubmit">
                            {{ $t('global.forms.steps.confirmation.sendButtonLabel') }}
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div>
                <ForceSubmissionModal
                    :state="state"
                    :submission-can-be-forced="submissionCanBeForced"
                    :format-value="formatValue"
                    @submit="onForceSubmissionSubmit"
                    @back="onForceSubmissionEdit"
                    @closing="disableButtons = false"
                >
                    <template #default="{ scope }">
                        <input ref="force-submission-modal" type="hidden" @click="scope.open" />
                    </template>
                </ForceSubmissionModal>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from '@makeabledk/vue-ui/support/http';
import { useToast } from 'vue-toastification';
import { Form as FormType, Batch, MultiStepFormState, FormElement, Farm, DirectoryFarm, Barn, DirectoryBarn } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import ConfirmationElement from '@/components/ui/ConfirmationElement.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import ForceSubmissionModal from '@/components/common/multiStepFormSteps/ForceSubmissionModal.vue';
import { MutationType } from '@/plugins/store/mutations';
import dynamicFormRules from '@/components/common/multiStepFormSteps/useDynamicFormRules';

export default defineComponent({
    components: { ConfirmationElement, CustomButton, ForceSubmissionModal },
    emits: ['submit', 'back', 'close', 'update:state'],
    props: {
        state: {
            type: Object as () => MultiStepFormState & Form<MultiStepFormState>,
            required: true,
        },
        config: {
            type: Object as () => { rejectable: boolean; submitActionType: ActionType; editButtonLabel?: string; disableRefetch?: boolean },
            required: true,
        },
        // Not used by this component
        header: {
            type: Object as () => null | { icon_url: string; text: string },
            default: null,
        },
    },
    data() {
        return {
            toast: useToast(),
            submissionCanBeForced: true,
            doubleWidthFieldIds: ['FF_Origin', 'FF_TransferTo'],
            disableButtons: false,
        };
    },
    computed: {
        sections() {
            return this.computedState.form?.sections
                .map((currentSection) => ({
                    ...currentSection,
                    children: currentSection.children.filter((currentFormElement) => currentFormElement.type !== 'button'),
                }))
                .filter((currentSection) => currentSection.children.length);
        },
        computedState: {
            get(): MultiStepFormState & Form<MultiStepFormState> {
                return this.$props.state;
            },
            set(newValue: MultiStepFormState) {
                this.$emit('update:state', newValue);
            },
        },
        formValid(): boolean {
            const fields = this.computedState.form?.sections.flatMap((currentSection) => currentSection.children);
            return fields
                ? fields.every((currentField) => {
                      const validatableRules = this.getRules(currentField);
                      return validatableRules.every((currentValidatableRule) => currentValidatableRule.validate(currentField.value));
                  })
                : true;
        },
    },
    methods: {
        getRules: dynamicFormRules,
        onForceSubmissionSubmit() {
            this.disableButtons = false;
            this.onSubmit();
        },
        onForceSubmissionEdit() {
            this.disableButtons = false;
            this.onBackClicked();
        },
        onBackClicked() {
            if (this.computedState.notification?.disabled || this.disableButtons) {
                return;
            }
            this.disableButtons = true;
            this.$emit('back');
        },
        async onSubmit() {
            if (this.computedState.notification?.disabled || !this.formValid) {
                return;
            }
            this.disableButtons = true;

            const form: Form<any> = new Form({});

            switch (this.config?.submitActionType) {
                case ActionType.PutRecordForm: {
                    form.setData({ record_id: this.computedState.record_id, form: this.computedState.form, confirmed: this.computedState.confirmed });
                    this.submitForm(form, ActionType.PutRecordForm);
                    break;
                }
                case ActionType.PutAssignmentForm: {
                    form.setData({ assignment_id: this.computedState.assignment_id, form: this.computedState.form, confirmed: this.computedState.confirmed });
                    this.submitForm(form, ActionType.PutAssignmentForm);
                    break;
                }
                case ActionType.PutAssignmentTemplateForm: {
                    form.setData({ assignment_template_id: this.computedState.assignment_template_id, form: this.computedState.form, confirmed: this.computedState.confirmed });
                    this.submitForm(form, ActionType.PutAssignmentTemplateForm);
                    break;
                }
                case ActionType.PutAlarmForm: {
                    form.setData({ alarm_id: this.computedState.alarm_id, form: this.computedState.form, confirmed: this.computedState.confirmed });
                    this.submitForm(form, ActionType.PutAlarmForm);
                    break;
                }
                case ActionType.PutNoteForm: {
                    form.setData({ note_id: this.computedState.note_id, form: this.computedState.form, confirmed: this.computedState.confirmed });
                    this.submitForm(form, ActionType.PutNoteForm);
                    break;
                }
                default: {
                    const formData = {
                        confirmed: this.computedState.confirmed,
                        form: this.computedState.form,
                        farm_id: (this.computedState.farm as Farm | undefined)?.farm_id || (this.computedState.farm as DirectoryFarm | undefined)?.id,
                        barn_id: (this.computedState.location as Barn | undefined)?.barn_id || (this.computedState.location as DirectoryBarn | undefined)?.id,
                        batch_id: (this.computedState.location as Batch | undefined)?.batch_id,
                        section_id: this.computedState.section?.id,
                        nref: this.computedState.notification?.resource?.reference_id,
                    };
                    Object.keys(formData).forEach((key) => (formData[key as keyof typeof formData] === undefined ? delete formData[key as keyof typeof formData] : {}));

                    form.setData(formData);
                    this.submitForm(form, ActionType.PutForm);
                }
            }
        },
        async onRejectClicked() {
            if (!this.computedState.notification || this.computedState.notification.disabled || this.computedState.notification.rejected) {
                return;
            }
            this.disableButtons = true;

            const update = [{ value: true, path: '/rejected', op: 'replace' }];
            const form = { notification: this.computedState.notification, update };
            await store.dispatch(ActionType.EditNotification, { form });
            this.toast.success(this.$t('global.forms.steps.confirmation.rejectionSuccessMessage'), { timeout: 5000 });
            this.$emit('close');
        },
        async submitForm(form: Form<any>, dispatchAction: ActionType) {
            try {
                await store.dispatch(dispatchAction, { form });
                // eslint-disable-next-line
            } catch (error: any) {
                if (error.status === 300 || error.status === 422) {
                    this.computedState.setErrors(form.errors.all());
                    this.submissionCanBeForced = error.status === 300;
                    (this.$refs['force-submission-modal'] as HTMLInputElement).click();
                }
            }

            if (form.successful) {
                if (!this.config.disableRefetch) {
                    store.dispatch(ActionType.GetFarms, { options: { ignoreCache: true } });
                    store.dispatch(ActionType.GetBarns, { options: { ignoreCache: true } });
                    store.dispatch(ActionType.GetSections, { options: { ignoreCache: true } });
                }

                store.commit(MutationType.SetFormRecentlySubmitted, true);
                this.$nextTick(() => {
                    store.commit(MutationType.SetFormRecentlySubmitted, false);
                });

                this.$emit('submit');
            } else {
                this.disableButtons = false;
            }
        },
        formatValue(element: FormElement) {
            if (element.value === null) {
                return '';
            }

            /* Special case that I was unfortunately unable to persuade the backend to handle differently */
            if (element.id === 'FF_PigletSupplier') {
                return element.value;
            }

            switch (element.type_settings.input_type) {
                case 'datetime': {
                    switch (element.type) {
                        case 'clockpicker': {
                            return this.$d(new Date(element.value), 'clock').replace(/(\d{2})(.)(\d{2})/, '$1:$3');
                        }
                        case 'datetimepicker': {
                            return this.$d(new Date(element.value), 'long').replace(/( \d{2})(.)(\d{2})/, '$1:$3');
                        }
                        default: {
                            return this.$d(new Date(element.value), 'short');
                        }
                    }
                }
                case 'datetime_with_recurrence': {
                    if (!element.value) {
                        return '';
                    }
                    if (element.value.type === 'single') {
                        return this.$d(new Date(element.value.start), 'long').replace(/( \d{2})(.)(\d{2})/, '$1:$3');
                    }
                    return this.$t('global.forms.steps.dynamicForm.fields.dateTimeRecurrence.confirmationFieldRecurring');
                }
                case 'keyvaluepair': {
                    return element.value.value;
                }
                case 'keyvaluepairlist': {
                    return element.value.map((currentKeyValuePair: { value: any; id: any }) => currentKeyValuePair.value).join(', ');
                }
                case 'bool': {
                    return element.value ? this.$t('global.forms.steps.confirmation.checkboxConfirmationElementChecked') : this.$t('global.forms.steps.confirmation.checkboxConfirmationElementUnchecked');
                }
                case 'double': {
                    return element.type === 'chartspread' ? `+/- ${this.$n(element.value)} kg` : this.$n(element.value);
                }
                case 'int': {
                    return this.$n(element.value);
                }
                default: {
                    return element.value;
                }
            }
        },
    },
});
</script>
